import styled, {keyframes} from 'styled-components';
import {DimensionProps} from '../customTypes';

interface NavIconProps extends DimensionProps {
    active: boolean;
}

const slideInAnimation = () => keyframes`
  0% {
    transform: translate(-50%, 100%);
  }

  100% {
    transform: translate(-50%, 0);
  }
`;

export const NavContainer = styled.div<DimensionProps>`
    width: ${DimensionProps => DimensionProps.width}vW;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: ${slideInAnimation} .2s forwards;
`;

export const NavBack = styled.div<DimensionProps>`
    width: ${DimensionProps => DimensionProps.width}vW;
    position: absolute;
    right: 6vW;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`;

export const NavIcons = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    position: absolute;
    bottom: 1vW;
    left: 5.5vW;
    grid-gap: 1.8vW;
`;

export const NavIconElement = styled.div<NavIconProps>`
    width: ${NavIconPros => NavIconPros.width}vW;
    cursor: pointer;
    
    ${NavIconProps => NavIconProps.active && `
        svg {
            #background-color {
                fill: #fbba00 !important;
            }
        }
    `}
`;