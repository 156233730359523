import {getRelativeWidth} from '../../config';
import {Pages} from '../../assets/ts/customTypes';
import {OverviewButtonContainer, OverviewButtonText} from '../../assets/ts/components/overviewbutton';
import ButtonContainerBG from '../../assets/backgrounds/bg-overview-button.svg';

export function OverviewButton({page, button, updateCurrentPage}: { page: Pages; button: string; updateCurrentPage: (page: Pages) => void }) {
    return (
        <OverviewButtonContainer onClick={() => updateCurrentPage(page)} width={getRelativeWidth(420)}>
            <img src={ButtonContainerBG} alt='background' />
            <OverviewButtonText>{button}</OverviewButtonText>
        </OverviewButtonContainer>
    );
}