import 'iframe-resizer/js/iframeResizer.contentWindow.min.js';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import {MatomoProvider, createInstance} from '@jonkoops/matomo-tracker-react';

(window as any).iFrameResizer = {
    onReady: () => {
        document.body.classList.add('app--in-iframe');
    },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const matomoInstance = createInstance({
    urlBase: 'https://www.omniplus.com/piwik/',
    siteId: 100,
    trackerUrl: 'https://www.omniplus.com/piwik/matomo.php',
    srcUrl: 'https://www.omniplus.com/piwik/matomo.js',
    linkTracking: false,
});

root.render(
    <React.StrictMode>
        <MatomoProvider value={matomoInstance}>
            <App />
        </MatomoProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
