import {useEffect, useState} from 'react';
import {Languages, Locale, Pages} from './assets/ts/customTypes';
import {Header} from './lib/components/Header';
import {Landingpage} from './lib/pages/Landingpage';
import {de} from './assets/locale/de';
import {en} from './assets/locale/en';
import {es} from './assets/locale/es';
import {fr} from './assets/locale/fr';
import {it} from './assets/locale/it';
import {nl} from './assets/locale/nl';
import { Infopage } from './lib/pages/Infopage';
import { Feedbackpage } from './lib/pages/Feedbackpage';
import { InfoLayer } from './lib/components/Infolayer';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { isInIframe } from './config';

function getDefaultLocale() {
    const language = new URLSearchParams(window.location.search).get('language');
    switch (language) {
        case 'de':
            return de;
        case 'en':
            return en;
        case 'es':
            return es;
        case 'fr':
            return fr;
        case 'it':
            return it;
        case 'nl':
            return nl;
        default:
            return en;
    }
}

export function App() {
    const [locale, setLocale] = useState<Locale>(getDefaultLocale);
    const [currentPage, setCurrentPage] = useState<Pages>(Pages.LANDINGPAGE);

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({
            documentTitle: 'Home'
        });
    }, [trackPageView]);

    return (
        <>
            {!isInIframe && <Header language={locale.language} heading={locale.headerHeading} updateLanguage={updateLanguage}/>}
            <InfoLayer locale={locale}/>
            {showPage()}
        </>
    );

    function showPage() {
        switch (currentPage) {
            case Pages.LANDINGPAGE:
                return (
                    <Landingpage updateCurrentPage={updateCurrentPage} locale={locale}/>
                )
            case Pages.FEEDBACKPAGE:
                if (locale === de) {
                    trackPageView({
                        documentTitle: currentPage,
                        href: 'https://emobility-ecosystem.buses.daimlertrucks.com/' + currentPage,
                    });
                }
                return (
                    <Feedbackpage updateCurrentPage={updateCurrentPage} locale={locale}/>
                );
            default:
                if (locale === de) {
                    trackPageView({
                        documentTitle: currentPage,
                        href: 'https://emobility-ecosystem.buses.daimlertrucks.com/' + currentPage,
                    });
                }
                return (
                    <Infopage currentPage={currentPage} updateCurrentPage={updateCurrentPage} locale={locale}/>
                );
        }
    }

    function updateLanguage(language: Languages) {
        switch (language) {
            case 'DE':
                setLocale(de);
                break;
            case 'EN':
                setLocale(en);
                break;
            case 'ES':
                setLocale(es);
                break;
            case 'FR':
                setLocale(fr);
                break;
            case 'IT':
                setLocale(it);
                break;
            case 'NL':
                setLocale(nl);
                break;
            default:
                setLocale(en);
                break;
        }
    }

    function updateCurrentPage(page: Pages) {
        setCurrentPage(page);
    }
}
