import {getRelativeWidth} from '../../config';
import {Pages} from '../../assets/ts/customTypes';
import {ButtonContainer, ButtonText} from '../../assets/ts/components/button';
import ButtonContainerBG from '../../assets/backgrounds/bg-button.svg';

export function Button({page, text, position, icon, updateCurrentPage}: {
    page: Pages;
    text: string;
    position: 'center' | 'right';
    icon?: 'left' | 'right';
    updateCurrentPage: (page: Pages) => void;
}) {
    return (
        <ButtonContainer position={position} onClick={() => updateCurrentPage(page)} width={getRelativeWidth(404)}>
            <img src={ButtonContainerBG} alt='background'/>
            {icon
                ? (<ButtonText icon={icon}>{text}</ButtonText>)
                : (<ButtonText>{text}</ButtonText>)
            }
        </ButtonContainer>
    );
}