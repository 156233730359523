import styled from 'styled-components';

export const ArrowContentBox = styled.div<{
    arrowTop?: string | number | undefined;
    arrowLeft?: string | number | undefined;
}>`
    position: relative;

    &:before {
        content: "";
        width: 1.5rem;
        height: 1.5rem;
        background: #fff;
        display: block;
        transform: rotate(45deg) translate(-1rem, 0rem);
        position: absolute;
        top: ${(props) => props.arrowTop ?? '0'};
        left: ${(props) => props.arrowLeft ?? '5.2rem'};
        box-shadow: 0.3rem -0.1rem 0.3rem 0 rgba(0,0,0,0.15);
    }
`;

export const ArrowContentBoxInner = styled.div`
    min-height: 100%;
    box-sizing: border-box;
    border-radius: 0.4rem;
    box-shadow: 0.25rem 0.25rem 0.3rem 0 rgba(0,0,0,0.15);
    background: #fff;
    padding: 1.75rem;
    padding-top: 2rem;
    padding-bottom: 0.75rem;
    position: relative;
`;

export const ArrowContentBoxQuote = styled.p`
    color: #7BB5CF;
    border-left: solid 0.25rem #7BB5CF;
    padding-left: 1rem;
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1.1rem;
`;

export const ArrowContentBoxFooter = styled.p`
    color: #999999;
    border-top: dotted 0.2rem #999999;
    padding-top: 0.5rem;
    margin: 0;
    font-size: 1rem;
`;
