import styled from 'styled-components';

export const HeaderContainer = styled.div`
    position: relative;
    z-index: 60;

    svg {
        width: 100%;

        text {
            fill: #fff;
            font-family: Daimler CS;
            font-weight: 400;
        }
    }
`;

export const LanguagePickerContainer = styled.div`
    position: absolute;
    right: 4%;
    top: 0;
    display: flex;
    transform: translateY(10%);
`;

export const LanguagePickerFlag = styled.div`
    height: 4.5vW;
    width: 4.5vW;
    display: flex;
    transform: translateY(3%);
`;

export const LanguagePickerSelect = styled.select`
    background: none;
    border: none;
    color: inherit;
    width: 100px;
    color: #fff;
`;

export const LanguagePickerOption = styled.option`
    background-color: #000;
`;