import styled, {keyframes} from 'styled-components';
import {DimensionProps} from '../customTypes';

const slideInAnimation = () => keyframes`
  0% {
    transform: translate(-50%, -100%);
  }

  100% {
    transform: translate(-50%, 0);
  }
`;

export const OverviewButtonContainer = styled.div<DimensionProps>`
    width: ${DimensionProps => DimensionProps.width}vW;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
    cursor: pointer;
    animation: ${slideInAnimation} .5s ease-in-out .5s forwards;
    z-index: 50;
`;

export const OverviewButtonText = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    font-size: 1.5rem;
    line-height: 2.25em;
    display: flex;    
    justify-content: center;
    font-weight: 600;
`;