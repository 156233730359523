export type Languages = 'DE' | 'EN' | 'ES'|'FR'|'IT'| 'NL';

export type AnyJsx = any; // TODO: Any value that may be used in JSX

export enum Pages {
    LANDINGPAGE = 'landingpage',
    BATTERY = 'battery',
    CHARGING = 'charginginfra',
    CONSTRUCTION = 'construction',
    SPAREPARTS = 'spareparts',
    SYSTEMDESIGN = 'systemdesign',
    TRANSFORMER = 'transformer',
    DIGITALSERVICE = 'digitalservice',
    MANAGEMENT = 'management',
    HYDROGEN = 'hydrogen',
    FEEDBACKPAGE = 'feedbackpage',
}

export type InfoBox = {
    icon: string,
    headline: string;
    list: string[]
}

export type ContentImages = {
    main: string;
    zoom: string;
}

export type FeedbackImages = {
    main: string;
}

export type InfoLayerType = {
    text: AnyJsx;
}

export type PageTypeInfopages = {
    [Pages: string]: PageTypeInfopage;
};

export type PageTypeLandingpage = {
    button: string;
    feedback: string;
    background: string;
}

export type PageTypeInfopage = {
    headline: string;
    intro?: string;
    text?: AnyJsx;
    infoBoxes?: InfoBox[];
    images: ContentImages;
}

export type PageTypeFeedbackpage = {
    headline: string;
    image: string;
    quote: {
        title: string;
        text: string;
        author: string;
    }
}

export type Locale = {
    language: Languages;
    headerHeading: string;
    overview: string;
    infolayer: InfoLayerType;
    landingpage: PageTypeLandingpage;
    feedbackpage: PageTypeFeedbackpage;
    pages: PageTypeInfopages;
};

export interface DimensionProps {
    width: number;
}
