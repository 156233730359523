export function HexagonImage({image}: { image: string }) {
    return (
        <svg viewBox="0 0 649 636" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M498.014496,141.279705 C500.206523,142.545398 502,145.652101 502,148.183488 L502,428.825947 C502,431.357334 500.206523,434.464037 498.014496,435.72973 L254.990336,576.0505 C252.798309,577.316193 249.211356,577.316653 247.018868,576.05096 L174.975591,534.457048 C172.783564,533.191355 169.19615,531.12022 167.004123,529.854526 L3.98550399,435.72973 C1.7934768,434.464037 0,431.357334 0,428.825947 L0,148.183488 C0,145.652101 1.7934768,142.545398 3.98550399,141.279705 L247.019328,0.949270157 C249.211356,-0.316423386 252.798309,-0.316423386 254.990336,0.949270157 L498.014496,141.279705 Z"
                    id="path-1"></path>
                <filter x="-10.4%" y="-7.1%" width="119.9%" height="117.3%" filterUnits="objectBoundingBox"
                        id="filter-3">
                    <feMorphology radius="8" operator="dilate" in="SourceAlpha"
                                  result="shadowSpreadOuter1"></feMorphology>
                    <feOffset dx="-2" dy="9" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"></feGaussianBlur>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out"
                                 result="shadowBlurOuter1"></feComposite>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix"
                                   in="shadowBlurOuter1"></feColorMatrix>
                </filter>
                <path
                    d="M271.824645,76.8835829 C273.02109,77.5723659 274,79.263015 274,80.6405809 L274,233.364554 C274,234.74212 273.02109,236.432769 271.824645,237.121552 L139.177992,313.483288 C137.981547,314.17207 136.023728,314.172321 134.827032,313.483538 L95.5046054,290.848376 C94.3081603,290.159593 92.3500898,289.032494 91.1536447,288.343711 L2.17535477,237.121552 C0.978909646,236.432769 0,234.74212 0,233.364554 L0,80.6405809 C0,79.263015 0.978909646,77.5723659 2.17535477,76.8835829 L134.827283,0.516587226 C136.023728,-0.172195742 137.981547,-0.172195742 139.177992,0.516587226 L271.824645,76.8835829 Z"
                    id="path-4"></path>
                <filter x="-19.0%" y="-13.0%" width="136.5%" height="131.8%" filterUnits="objectBoundingBox"
                        id="filter-6">
                    <feMorphology radius="8" operator="dilate" in="SourceAlpha"
                                  result="shadowSpreadOuter1"></feMorphology>
                    <feOffset dx="-2" dy="9" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"></feGaussianBlur>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out"
                                 result="shadowBlurOuter1"></feComposite>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix"
                                   in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1137.000000, -173.000000)">
                    <g transform="translate(1172.000000, 197.000000)">
                        <g>
                            <path
                                d="M551.027809,203.018123 C553.216929,204.290094 555.004595,207.402577 555,209.934548 L554.556643,452.083996 C554.552039,454.615967 552.753784,457.723387 550.560981,458.989372 L340.8447,580.064096 C338.651437,581.330082 335.075645,581.308905 332.897574,580.0176 L273.260983,544.652406 C271.083373,543.361101 267.50712,541.268311 265.314317,540.002326 L124.987374,458.989372 C122.794111,457.723387 121,454.615967 121,452.083996 L121,209.934548 C121,207.402577 122.794111,204.295158 124.986914,203.029172 L334.711942,81.9461618 C336.904745,80.6801761 340.490205,80.6852401 342.679786,81.9576707 L551.027809,203.018123 Z"
                                id="background" fill="#FFFFFF"></path>
                            <mask id="mask-big" fill="white">
                                <use xlinkHref="#path-1"></use>
                            </mask>
                            <g id="border">
                                <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-1"></use>
                                <path stroke="#FFFFFF" strokeWidth="8"
                                      d="M251.004832,-4 C253.185596,-4 255.344431,-3.46518997 256.990554,-2.51470572 L500.014645,137.81569 C503.306536,139.716453 506,144.38193 506,148.183488 L506,428.825947 C506,432.627505 503.306536,437.292983 500.01461,439.193766 L256.990486,579.514515 C253.698519,581.415322 248.311676,581.415955 245.018879,579.515068 L172.975528,537.921114 L165.004028,533.318573 L1.9853546,439.193746 C-1.30653566,437.292983 -4,432.627505 -4,428.825947 L-4,148.183488 C-4,144.38193 -1.30653566,139.716453 1.98534588,137.815695 L245.019179,-2.5147452 C246.665234,-3.46518997 248.824069,-4 251.004832,-4 Z"
                                      fill="#FFFFFF" fillRule="evenodd"></path>
                            </g>
                            <g className="main-image" mask="url(#mask-big)">
                                <image x="0" y="0" width="502" height="577" xlinkHref={image}></image>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}