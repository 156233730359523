export const GRID_WIDTH = 1920;
export const GRID_HEIGHT = 1080;
export const BREAKPOINT = 768;

export const getRelativeWidth = (width: number) => {
    return width / GRID_WIDTH * 100;
}

export const getRelativeHeight = (width: number) => {
    return width / GRID_HEIGHT * 100;
}

export const isInIframe = window.parent !== window;
