import styled from 'styled-components';
import {BREAKPOINT} from '../../../config';

export const InfoLayerContainer = styled.div`
  position: relative;
  flex: 1;
  display: block;
  background: radial-gradient(at center, #e7e7e7 0, #ddd 100%);

  @media (min-width: ${BREAKPOINT}px) {
    display: none;
  }
`;

export const InfoLayerContent = styled.div`
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 100%;
  text-align: center;
`;

export const InfoLayerText = styled.div`
  color: #666;
  font-size: 3em;
  font-weight: 400;
  padding: 1em;
  max-width: 70%;
  margin: 0 auto;
  
  p {
    margin: 0 0 1em;
  }
`;