// Don't change manually as this is generated automatically!

export const es_ES = {
    '#Dictionary-eMobilitaets-Oekosystem#': "Ecosistema de eMobility",
    '#HL-eMobilitaetsOekosystem-DB#': "Ecosistema de eMobility de Daimler Buses",
    '#SHL-eSystem-Klick-Explainer#': "Diseño del sistema",
    '#SL-SystemDesign-eSystem-Klick-Explainer#': "Gracias a estudios de viabilidad somos capaces de planificar un sitema de movilidad eléctrica eficaz para las empresas de transporte público.",
    '#AB1-SystemDesign-eSystem-Klick-Explainer#': "En un estudio de viabilidad, los asesores de eMobility de Daimler Buses comprueban los requisitos de todas las líneas y rutas existentes en un modelo de simulación especialmente adaptado al eCitaro. Para ello, se determina el estado de carga durante el día y, en caso necesario, las pausas necesarias para las recargas. Las líneas se optimizan para los autobuses eléctricos.",
    '#AB2-SystemDesign-eSystem-Klick-Explainer#': "El diseño del sistema incluye el diseño del depósito de vehículos en su conjunto, incluida la infraestructura de carga. Esto incluye el cálculo de la potencia eléctrica o la cantidad de energía necesaria. Además, se crean conceptos de carga y se lleva a cabo una optimización de la potencia de carga mediante la gestión del depósito de vehículos.",
    '#ZHL-Vorteile-eSysem-Klick-Explainer#': "Ventajas para usted",
    '#BP1-SystemDesign-eSystem-Klick-Explainer#': "Transparencia en cuanto a la viabilidad de los recorridos diarios de las líneas existentes con el eCitaro",
    '#BP2-SystemDesign-eSystem-Klick-Explainer#': "Apoyo para la optimización de los recorridos",
    '#BP3-SystemDesign-eSystem-Klick-Explainer#': "Ayuda para la decisión sobre la configuración óptima de la batería y del concepto de carga",
    '#BP4-SystemDesign-eSystem-Klick-Explainer#': "Concepto global para la conversión del depósito de vehículos para el funcionamiento de autobuses eléctricos",
    '#ZHL1-eSystem-Klick-Explainer#': "Acumulador de batería",
    '#SL1-eSystem-Klick-Explainer#': "Con Mercedes-Benz Energy le proporcionamos una segunda vida a las baterías de autobús usadas.",
    '#AB1-eSystem-Klick-Explainer#': "Utilizando las baterías usadas como acumuladores de energía, su ciclo de vida se puede prolongar considerablemente y la sostenibilidad de su flota de autobuses eléctricos puede ser aún mayor. En combinación con un sistema inteligente de carga y gestión de energía, también puede reducir los picos de potencia y, por tanto, los costes de electricidad. Se obtiene un potencial de optimización adicional en combinación con una instalación fotovoltaica, que durante el día genera energía renovable que luego puede aprovecharse para cargar los autobuses por la noche.",
    '#BP1-eSystem-Klick-Explainer#': "Aumento de la rentabilidad",
    '#BP2-eSystem-Klick-Explainer#': "Reducción de picos de carga",
    '#BP3-eSystem-Klick-Explainer#': "Mejora de la huella de carbono",
    '#ZHL2-eSystem-Klick-Explainer#': "Transformador",
    '#SL2-eSystem-Klick-Explainer#': "Le asesoramos en la elección del transformador adecuado para su depósito de vehículos.",
    '#BE1_eSystem-Klick-Explainer#': "Parque de vehículos del cliente",
    '#AB2-eSystem-Klick-Explainer#': "Para poder suministrar energía a una flota de autobuses alimentados por batería, la energía de la red de media tensión (normalmente 6 kV - 60 kV) debe alcanzar un nivel de tensión de 400 V CA. Dependiendo de los requisitos y del espacio en el depósito de vehículos, esto puede hacerse con un transformador compacto o, en el caso de grandes flotas, con una estación transformadora.",
    '#BP1-Transformator-eSystem-Klick-Explainer#': "Suministro de la potencia total necesaria en función del tamaño de la flota de autobuses y del concepto de carga",
    '#BP2-Transformator-eSystem-Klick-Explainer#': "Seguridad en la planificación teniendo en cuenta ampliaciones de flota previstas",
    '#ZHL3-eSystem-Klick-Explainer#': "Trabajos de construcción e instalaciones eléctricas",
    '#SL3-eSystem-Klick-Explainer#': "Nos encargamos de un uso de la energía eficiente y segura en sus cocheras: desde la planificación hasta la ejecución.",
    '#AB3-eSystem-Klick-Explainer#': "Los asesores de eMobility de Daimler Buses se encargan de planificar para usted todos los trabajos necesarios. La coordinación y la ejecución están a cargo de la dirección central del proyecto.",
    '#BP1-Bauarbeiten-eSystem-Klick-Explainer#': "Implementación teniendo en cuenta el objetivo general de ampliación del depósito de vehículos",
    '#BP2-Bauarbeiten-eSystem-Klick-Explainer#': "Consideración de los procesos operativos individuales en la planificación de la construcción",
    '#BP3-Bauarbeiten-eSystem-Klick-Explainer#': "Los trabajos de construcción pueden llevarse a cabo sin interrumpir el servicio",
    '#ZHL4-eSystem-Klick-Explainer#': "Gestión de la carga y del depósito de vehículos",
    '#SL4-eSystem-Klick-Explainer#': "Con nuestras soluciones de gestión de carga y del parque de vehículos, tendrá controlados en todo momento todos los procesos de carga y podrá reducir en gran medida sus costes de electricidad.",
    '#AB4-eSystem-Klick-Explainer#': "Mediante una gestión de carga profesional, puede optimizar el rendimiento de carga de toda la flota para que sus autobuses se carguen y preacondicionen a tiempo con un rendimiento global controlado. Esto permite reducir los costosos picos de carga de la red y adquirir electricidad en la franja de precios más favorable. Los horarios de carga se crean automáticamente dentro de las franjas de carga especificadas y el preacondicionamiento de los vehículos también se controla de forma centralizada para garantizar la máxima autonomía de los autobuses. Para ello, ofrecemos soluciones de gestión de carga para pequeñas flotas, pero también podemos controlar óptimamente grandes flotas de autobuses eléctricos con soluciones integradas de gestión de cocheras eléctricas y asignar recorridos y plazas de aparcamiento con precisión.",
    '#BP1-Lademanagement-eSystem-Klick-Explainer#': "Reducción de costes gracias a los picos de carga bajos",
    '#BP2-Lademanagement-eSystem-Klick-Explainer#': "Planes de carga automáticos para todos los autobuses, orientados a horas de salida y recorridos",
    '#BP3-Lademanagement-eSystem-Klick-Explainer#': "Control central del preacondicionamiento para una autonomía máxima y un mayor confort",
    '#BP4-Lademanagement-eSystem-Klick-Explainer#': "Carga optimizada en la mejor franja de precios",
    '#ZHL5-eSystem-Klick-Explainer#': "Infraestructura de carga",
    '#SL5-eSystem-Klick-Explainer#': "Le apoyamos con soluciones de infraestructura de carga a medida por parte de fabricantes europeos de sistemas de carga.",
    '#AB5-eSystem-Klick-Explainer#': "El concepto de funcionamiento óptimo para un autobús de propulsión eléctrica depende en gran medida de las condiciones de uso correspondientes. Los asesores de eMobility de Daimler Buses Solutions, en función de las necesidades, recomiendan la infraestructura de carga más adecuada: Carga por enchufe en el depósito o combinación con pantógrafos. Además, en función del perfil de las líneas, hacemos recomendaciones sobre la tecnología de carga adecuada.",
    '#BP1-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Infraestructura de carga y vehículo de un solo proveedor",
    '#BP2-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Combinación perfecta de gestión de carga, infraestructura y vehículo",
    '#BP3-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Compatibilidad garantizada con los sistemas de baterías del eCitaro gracias a exhaustivas pruebas de interoperabilidad",
    '#ZHL6-eSystem-Klick-Explainer#': "Repuestos, contratos eService y red de servicios",
    '#SL6-eSystem-Klick-Explainer#': "Con los contratos de OMNIplus eService para autobuses eléctricos, le brindamos un servicio profesional a costes planificables.",
    '#AB6-eSystem-Klick-Explainer#': "Confíe en nuestra acreditada oferta de contratos de OMNIplus eService que hemos adaptado a los requisitos de la movilidad eléctrica. Puede configurar la extensión de trabajos de servicio óptima para su flota de autobuses eléctricos de forma personalizada y a la medida de sus necesidades. Por ejemplo, el contrato eService ePremium cubre todos los mantenimientos según las especificaciones del fabricante y todas las reparaciones de la red de a bordo de alto voltaje. El paquete también incluye otras reparaciones en todo el vehículo, incluidas todas las piezas de repuesto necesarias. Para el suministro profesional de recambios originales , tiene a su disposición la mayor y más densa red de talleres de servicio para autobuses y autocares de Europa,﻿ con más de 600 establecimientos de servicio autorizados. De este modo, siempre viajará de forma segura, fiable y rentable con su eCitaro totalmente eléctrico.",
    '#BP1-Ersatzteile-eSystem-Klick-Explainer#': "Dos contratos de OMNIplus eService a elegir: eBasic o ePremium",
    '#BP2-Ersatzteile-eSystem-Klick-Explainer#': "Una opción para la batería de alto voltaje: garantía ampliada de la batería",
    '#BP3-Ersatzteile-eSystem-Klick-Explainer#': "Oferta de servicios de movilidad eléctrica preparados para el futuro",
    '#BP4-Ersatzteile-eSystem-Klick-Explainer#': "Precios atractivos",
    '#BP5-Ersatzteile-eSystem-Klick-Explainer#': "Contratos de servicio y mantenimiento también para la infraestructura de carga",
    '#ZHL7-eSystem-Klick-Explainer#': "Servicios digitales",
    '#SL7-eSystem-Klick-Explainer#': "Le ofrecemos el mundo de servicios digitales de OMNIplus ON para su Mercedes-Benz eCitaro y eCitaro fuel cell.",
    '#AB7-eSystem-Klick-Explainer#': "OMNIplus ON le ofrece una gran variedad de servicios digitales para la máxima disponibilidad  y rentabilidad posible de los vehículos. Como, por ejemplo, el OMNIplus ON Uptime pro con telediagnóstico. De este modo, tendrá siempre a la vista el estado de sus vehículos y podrá optimizar la planificación del mantenimiento. Con OMNIplus ON monitor también tiene la posibilidad de supervisar el estado de carga y la autonomía restante de sus vehículos, así como analizar su consumo energético, por ejemplo, también para el nuevo Mercedes-Benz eCitaro fuel cell. Además, las interfaces virtuales de OMNIplus ON permiten una sencilla integración de datos en su propio sistema de gestión de flotas o de cocheras. De este modo, el nuevo Data Package TiGR, certificado según el estándar ITxPT unificado, proporciona una base de datos homogénea para toda la flota y simplifica así el análisis de sus vehículos. Asimismo, todos los datos del vehículo se ponen a disposición en tiempo real. Esto se realiza en todo momento de forma legalmente segura y en el marco del nuevo Reglamento R155 de la ONU con respecto a la ciberseguridad de los vehículos. Por otra parte, los paquetes de datos pueden utilizarse para lograr una integración total de los datos en su propio sistema de gestión de flotas o parques de vehículos utilizando, entre otras cosas, el nuevo paquete TiGR estandarizado por ITxPT.",
    '#BP1-DigitaleServices-eSystem-Klick-Explainer#': "Máxima disponibilidad posible de los vehículos",
    '#BP2-DigitaleServices-eSystem-Klick-Explainer#': "Transparencia sobre la autonomía y el consumo de los vehículos eCitaro",
    '#BP3-DigitaleServices-eSystem-Klick-Explainer#': "Máxima seguridad de sus datos",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff#': "Infraestructura de hidrógeno",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy_head#': "Le ayudamos con soluciones de infraestructura a medida para el suministro de hidrógeno.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy#': "El concepto de servicio óptimo para un autobús de propulsión alternativa depende en gran medida de las condiciones de servicio respectivas. Para prolongar la autonomía, nuestro Mercedes-Benz eCitaro fuel cell lleva a bordo, además de las baterías, una pila de combustible alimentada por hidrógeno (H2). Los asesores de movilidad eléctrica de Daimler Buses determinan la infraestructura de depósito más adecuada en función de las cantidades de hidrógeno requeridas y de las condiciones locales. Nosotros analizamos si se puede utilizar una estación de hidrógeno en el entorno y le ponemos en contacto con uno de nuestros socios de cooperación, o si tiene sentido instalarla en sus propias cocheras utilizando su propia estación de servicio. Siempre implementamos la mejor solución para su flota.  Además, se comprueba si el suministro de hidrógeno se puede realizar con una solución de remolque o un electrolizador. Asimismo, recibirá recomendaciones y ofertas sobre el dispositivo de alimentación eléctrica adecuado para sus flotas de autobuses eléctricos.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile#': "Ventajas para usted:",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b1#': "Infraestructura y vehículo de un único proveedor",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b2#': "Interacción perfecta de la gestión de carga, la infraestructura y el vehículo",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b3#': "Ayuda en la toma de decisiones para elegir el concepto de repostaje óptimo de H2",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b4#': "Colaboración con empresas expertas",
} as const;
