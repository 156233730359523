import {getRelativeWidth} from '../../config';
import {NavContainer, NavIcons, NavIconElement, NavBack} from '../../assets/ts/components/navigation';
import NavContainerBG from '../../assets/backgrounds/bg-navi.svg';
import NavBackIcon from '../../assets/icons/navi/back.svg';
import {Pages} from '../../assets/ts/customTypes';
import {Icon} from './Icon';

export const Navigation = ({currentPage, updateCurrentPage}: { currentPage: Pages, updateCurrentPage: (page: Pages) => void }) => {
    const NavItem = ({page} : {page: Pages}) => {
        const active = currentPage === page;

        return (
            <NavIconElement onClick={() => updateCurrentPage(page)} width={getRelativeWidth(62)} active={active}>
                <Icon page={page} type='navi' />
            </NavIconElement>
        )
    }

    return (
        <NavContainer width={getRelativeWidth(1100)}>
            <img src={NavContainerBG} alt='background' />
            <NavIcons>
                <NavItem page={Pages.MANAGEMENT} />
                <NavItem page={Pages.CHARGING} />
                <NavItem page={Pages.DIGITALSERVICE} />
                <NavItem page={Pages.TRANSFORMER} />
                <NavItem page={Pages.BATTERY} />
                <NavItem page={Pages.CONSTRUCTION} />
                <NavItem page={Pages.SPAREPARTS} />
                <NavItem page={Pages.HYDROGEN} />
            </NavIcons>
            <NavBack onClick={() => updateCurrentPage(Pages.LANDINGPAGE)} width={getRelativeWidth(35)}>
                <img src={NavBackIcon} alt='back' />
            </NavBack>
        </NavContainer>
    );
}