import styled, {keyframes} from 'styled-components';
import {DimensionProps} from '../customTypes';
import ArrowLeft from '../../icons/navi/arrow-left.svg';
import ArrowRight from '../../icons/navi/arrow-right.svg';

interface ButtonProps extends DimensionProps {
    position: 'center' | 'right';
}

interface ButtonTextProps {
    icon?: 'left' | 'right';
}

const slideInAnimation = () => keyframes`
  0% {
    transform: translate(-50%, 100%);
  }

  100% {
    transform: translate(-50%, 0);
  }
`;

export const ButtonContainer = styled.div<ButtonProps>`
  width: ${ButtonProps => ButtonProps.width}vW;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 100%);
  cursor: pointer;
  animation: ${slideInAnimation} .5s ease-in-out .5s forwards;
  z-index: 50;

  ${({position}) => position === 'right' && `
    right: -8%;
  `}

  ${({position}) => position === 'center' && `
    left: 50%;
  `}
`;

export const ButtonText = styled.span<ButtonTextProps>`
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  color: #fff;
  font-size: 1.5rem;
  line-height: 2.2em;
  display: flex;
  justify-content: center;
  font-weight: 600;
  
  ${({icon}) => icon === 'left' && `
    &:before {
        content: url(${ArrowLeft});
        width: .5em;
        margin-right: .5em;
    }
  `}

  ${({icon}) => icon === 'right' && `
    &:after {
        content: url(${ArrowRight});
        width: .5em;
        margin-left: .5em;
    }
  `}
`;