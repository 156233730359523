import React from 'react';
import {Languages} from '../../assets/ts/customTypes';
import {
    LanguagePickerContainer,
    LanguagePickerFlag,
    LanguagePickerOption,
    LanguagePickerSelect
} from '../../assets/ts/layout/header';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import FlagDE from '../../assets/flags/flag-de.svg';
import FlagEN from '../../assets/flags/flag-en.svg';
import FlagES from '../../assets/flags/flag-es.svg';
import FlagFR from '../../assets/flags/flag-fr.svg';
import FlagIT from '../../assets/flags/flag-it.svg';
import FlagNL from '../../assets/flags/flag-nl.svg';


const Flag = ({ language }: { language: Languages }) => {
    let src = '';
    switch (language) {
        case 'DE':
            src = FlagDE;
            break;
        case 'EN':
            src = FlagEN;
            break;
        case 'ES':
            src = FlagES;
            break;
        case 'FR':
            src = FlagFR;
            break;
        case 'IT':
            src = FlagIT;
            break;
        case 'NL':
            src = FlagNL;
            break;
        default:
            src = FlagEN;
            break;
    }
    return <img src={src} alt=''/>
};

export function LanguagePicker({
    language,
    updateLanguage
}: { language: Languages; updateLanguage: (language: Languages) => void }) {
    const { trackEvent } = useMatomo();
    return (
        <LanguagePickerContainer>
            <LanguagePickerFlag>
                <Flag language={language} />
            </LanguagePickerFlag>
            <LanguagePickerSelect
                onChange={handleSelection}
                aria-label='Language:'
                name='language-picker-select'
                id='language-picker-select'
            >
                <LanguagePickerOption value='EN'>English</LanguagePickerOption>
                <LanguagePickerOption value='DE'>Deutsch</LanguagePickerOption>
                <LanguagePickerOption value='ES'>Español</LanguagePickerOption>
                <LanguagePickerOption value='FR'>Français</LanguagePickerOption>
                <LanguagePickerOption value='IT'>Italiano</LanguagePickerOption>
                <LanguagePickerOption value='NL'>Nederlands</LanguagePickerOption>
            </LanguagePickerSelect>
        </LanguagePickerContainer>
    );

    function handleSelection(event: React.ChangeEvent<HTMLSelectElement>) {
        switch (event.target.value) {
            case 'DE':
                updateLanguage('DE');
                break;
            case 'EN':
                updateLanguage('EN');
                break;
            case 'ES':
                updateLanguage('ES');
                break;
            case 'FR':
                updateLanguage('FR');
                break;
            case 'IT':
                updateLanguage('IT');
                break;
            case 'NL':
                updateLanguage('NL');
                break;
            default:
                updateLanguage('EN');
                break;
        }
        trackEvent({
            category: 'language switch',
            action: 'click-event',
            name: event.target.value,
        });
    }
}