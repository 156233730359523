import {MainContainer} from '../../assets/ts/layout/main';
import {ContentContainer} from '../../assets/ts/layout/content';
import {Pages, Locale} from '../../assets/ts/customTypes';
import {getRelativeWidth} from '../../config';
import {HexagonImage} from '../components/HexagonImage';
import {PageHeadline} from '../components/PageHeadline';
import {Button} from '../components/Button';
import {QuoteImage, QuoteContainer, QuoteTitle} from '../../assets/ts/pages/feedbackpage';
import {ArrowContentBox, ArrowContentBoxInner, ArrowContentBoxQuote, ArrowContentBoxFooter} from '../../assets/ts/components/arrowcontentbox';

interface FeedbackpageProps {
    updateCurrentPage: (page: Pages) => void;
    locale: Locale;
}

export function Feedbackpage(props: FeedbackpageProps) {
    const {locale, updateCurrentPage} = props;
    const {headline, image, quote} = locale[Pages.FEEDBACKPAGE];
    const {overview} = locale;

    return (
        <MainContainer name='feedbackpage'>
            <PageHeadline text={headline} />
            <ContentContainer>
                <QuoteImage width={getRelativeWidth(649)}>
                    <HexagonImage image={image}/>
                </QuoteImage>
                <QuoteContainer>
                    <QuoteTitle>{quote.title}</QuoteTitle>
                    <ArrowContentBox arrowTop='5rem' arrowLeft={0}>
                        <ArrowContentBoxInner>
                            <ArrowContentBoxQuote>{quote.text}</ArrowContentBoxQuote>
                            <ArrowContentBoxFooter>{quote.author}</ArrowContentBoxFooter>
                        </ArrowContentBoxInner>
                    </ArrowContentBox>
                </QuoteContainer>
            </ContentContainer>
            <Button page={Pages.LANDINGPAGE} text={overview} position={'center'} icon={'left'} updateCurrentPage={updateCurrentPage} />
        </MainContainer>
    );
}