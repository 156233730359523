import styled from 'styled-components';
import {DimensionProps} from '../customTypes';
import {GRID_HEIGHT, GRID_WIDTH} from '../../../config';
import Line from '../../content/separator.svg';
import ListIcon from '../../content/hexagon-list.svg';

export const ContentInfoBoxContainer = styled.div`
    display: flex;
    position: relative;
    
    &:not(:last-child) {
        margin-bottom: ${40 / GRID_WIDTH * 100}vW;
        padding-bottom: ${40 / GRID_WIDTH * 100}vW;
    
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: ${3 / GRID_WIDTH * 100}vW;
            background: url(${Line}) no-repeat;
            background-size: 100% auto;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
`;

export const ContentInfoBoxIcon = styled.div<DimensionProps>`
     width: ${DimensionProps => DimensionProps.width}vW;
     margin-right: ${50 / GRID_WIDTH * 100}vW;
`;

export const ContentInfoBoxListWrapper = styled.div`
    //height: ${(150 / GRID_HEIGHT) * 100}vW;

    @media (min-aspect-ratio: 18/9) {
        overflow-y: scroll;
        scrollbar-color: #7bb5cf #0000;
        scrollbar-width: thin;
        padding-right: 5px;
        &:after {
            content: '';
            height: 10vh;
            display: block;
        }
        &::-webkit-scrollbar {
            width: 5px;
        }
    }

        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

`;

export const ContentInfoBoxHeadline = styled.h4`
    color: #7BB5CF;
    font-weight: 400;
    font-size: 1.5vW;
    line-height: 1;
    margin-bottom: .7em;
`;

export const ContentInfoBoxListContainer = styled.ul`
    margin: 0;
    padding: 0;
`;

export const ContentInfoBoxListItem =  styled.li`
    position: relative;
    padding: 0 0 0 1.5em;
    list-style: none;
    color: #666;
    font-size: 1vW;
    margin-bottom: .2em;
    
    &:before {
        content: '';
        background: url(${ListIcon}) no-repeat left center;
        width: 1em;
        height: 1.5em;
        display: inline-block;
        background-size: 60% auto;
        position: absolute;
        left: 0;
    }
`;