// Don't change manually as this is generated automatically!

export const de_DE = {
    '#Dictionary-eMobilitaets-Oekosystem#': "eMobilitäts-Ökosystem",
    '#HL-eMobilitaetsOekosystem-DB#': "Das eMobilitäts-Ökosystem von Daimler Buses",
    '#SHL-eSystem-Klick-Explainer#': "System-Design",
    '#SL-SystemDesign-eSystem-Klick-Explainer#': "Mit softwaregestützten Machbarkeitsstudien machen wir den elektrischen ÖPNV verlässlich planbar und erstellen das System-Design für Ihren Betriebshof.",
    '#AB1-SystemDesign-eSystem-Klick-Explainer#': "In einer Machbarkeitsstudie überprüfen die eMobilitäts-Berater von Daimler Buses in einem speziell auf den eCitaro abgestimmten Simulationsmodell den Energiebedarf aller bestehenden Linien und Strecken. Dabei wird der Ladezustand im Tagesverlauf ermittelt und ggf. notwendige Ladepausen vorgeschlagen. Geeignete Buslinien werden für Elektrobusse optimiert.",
    '#AB2-SystemDesign-eSystem-Klick-Explainer#': "Das System-Design beinhaltet das Layout des Betriebshofes als Ganzes inklusive der Ladeinfrastruktur. Das schließt die Berechnung der benötigten elektrischen Leistung bzw. Energiemenge mit ein. Zusätzlich werden Ladekonzepte erstellt und über das Betriebshofmanagement eine Ladeleistungsoptimierung durchgeführt.",
    '#ZHL-Vorteile-eSysem-Klick-Explainer#': "Ihre Vorteile",
    '#BP1-SystemDesign-eSystem-Klick-Explainer#': "Transparenz über die Realisierbarkeit der Tagesumläufe vorhandener Buslinien mit dem eCitaro",
    '#BP2-SystemDesign-eSystem-Klick-Explainer#': "Unterstützung bei der Umlaufoptimierung",
    '#BP3-SystemDesign-eSystem-Klick-Explainer#': "Entscheidungshilfe für die optimale Batteriekonfiguration und das Ladekonzept",
    '#BP4-SystemDesign-eSystem-Klick-Explainer#': "Gesamtkonzept für die Umstellung des Betriebshofes auf den Betrieb von Elektrobussen",
    '#ZHL1-eSystem-Klick-Explainer#': "Batteriespeicher",
    '#SL1-eSystem-Klick-Explainer#': "Wir verleihen gebrauchten Busbatterien ein zweites Leben – zusammen mit Mercedes-Benz Energy.",
    '#AB1-eSystem-Klick-Explainer#': "Mit einem stationären Energiespeicher aus gebrauchten Elektrobusbatterien lässt sich deren Lebenszyklus deutlich verlängern und die Nachhaltigkeit Ihrer Elektrobusflotte weiter steigern. In Kombination mit einem intelligenten Lade- und Energiemanagement-System können Sie darüber hinaus Leistungsspitzen reduzieren und so Stromkosten senken. Weiteres Optimierungspotential ergibt sich in Verbindung mit einer Photovoltaik-Anlage, die tagsüber die regenerative Energie erzeugt, welche Sie dann nachts zum Laden der Busse nutzen können.",
    '#BP1-eSystem-Klick-Explainer#': "Erhöhung der Wirtschaftlichkeit",
    '#BP2-eSystem-Klick-Explainer#': "Reduzierung von Lastspitze",
    '#BP3-eSystem-Klick-Explainer#': "Verbesserung des CO2-Fußabdrucks",
    '#ZHL2-eSystem-Klick-Explainer#': "Transformator",
    '#SL2-eSystem-Klick-Explainer#': "Wir beraten Sie bei der individuellen Auswahl des richtigen Transformators für Ihren Betriebshof.",
    '#BE1_eSystem-Klick-Explainer#': "Kundendepot",
    '#AB2-eSystem-Klick-Explainer#': "Um eine Flotte von batteriebetriebenen Bussen mit Energie versorgen zu können, muss die Energie aus dem Mittelspannungsnetz (i.d.R. 6 kV - 60 kV) auf ein Spannungsniveau von 400V AC herabgesetzt werden. Je nach Anforderungen und Platzverhältnissen im Fahrzeugdepot kann dies mit einem Kompakt-Trafo oder bei großen Flotten mit einer begehbaren Trafostation erfolgen.",
    '#BP1-Transformator-eSystem-Klick-Explainer#': "Bereitstellung der benötigten Gesamtleistung je nach Busflottengröße und Ladekonzept",
    '#BP2-Transformator-eSystem-Klick-Explainer#': "Planungssicherheit durch Abstimmung der Energieversorgung auf geplante Ausbaustufen Ihrer Busflotte",
    '#ZHL3-eSystem-Klick-Explainer#': "Bauarbeiten und Elektro-Installationen",
    '#SL3-eSystem-Klick-Explainer#': "Wir sorgen für die effiziente und sichere Energieversorgung auf Ihrem Betriebshof – von der Planung bis zur Ausführung.",
    '#AB3-eSystem-Klick-Explainer#': "Die eMobilitäts-Berater von Daimler Buses übernehmen für Sie die Planung aller notwendigen Bauarbeiten zur Stromversorgung der Ladegeräte. Koordination und Ausführung liegt in der Hand einer zentralen Projektleitung.",
    '#BP1-Bauarbeiten-eSystem-Klick-Explainer#': "Umsetzung mit Blick auf das Gesamtausbauziel des Betriebshofes",
    '#BP2-Bauarbeiten-eSystem-Klick-Explainer#': "Berücksichtigung der individuellen betrieblichen Abläufe in der Bauplanung",
    '#BP3-Bauarbeiten-eSystem-Klick-Explainer#': "Bauarbeiten können im laufenden Betrieb erfolgen",
    '#ZHL4-eSystem-Klick-Explainer#': "Lade- und Betriebshof-Management",
    '#SL4-eSystem-Klick-Explainer#': "Mit unseren Lade- und Betriebshofmanagement-Lösungen haben Sie jederzeit alle Ladevorgänge im Blick und können Ihre Stromkosten stark reduzieren.",
    '#AB4-eSystem-Klick-Explainer#': "Mit einem professionellen Lademanagement können Sie die Ladeleistung der ganzen Flotte optimieren, sodass Ihre Busse bei kontrollierter Gesamtleistung rechtzeitig geladen und vorkonditioniert sind. Kostenintensive Netzlastspitzen lassen sich so reduzieren und der Strombezug kann im günstigsten Preisfenster stattfinden. Die Ladepläne werden automatisch innerhalb der vorgegebenen Lastfenster erstellt und auch die Vorkonditionierung der Fahrzeuge wird zentral gesteuert, um die maximale Reichweite der Busse zu gewährleisten. \nDafür bieten wir übersichtliche Lademanagement-Lösungen für kleine Flotten, können aber auch große Elektrobusflotten mit integrierten Elektrobetriebshofmanagement-Lösungen optimal steuern und Umläufe und Stellplätze zielgenau zuweisen.",
    '#BP1-Lademanagement-eSystem-Klick-Explainer#': "Kostenreduzierung durch geringe Lastspitzen",
    '#BP2-Lademanagement-eSystem-Klick-Explainer#': "Automatische Ladepläne für alle Busse\nausgerichtet auf Abfahrtszeiten und Umläufe",
    '#BP3-Lademanagement-eSystem-Klick-Explainer#': "Zentrale Steuerung der Vorkonditionierung für maximale Reichweite und erhöhten Komfort",
    '#BP4-Lademanagement-eSystem-Klick-Explainer#': "Netzschonendes Laden im besten Preisfenster",
    '#ZHL5-eSystem-Klick-Explainer#': "Ladeinfrastruktur",
    '#SL5-eSystem-Klick-Explainer#': "Wir unterstützen Sie mit maßgeschneiderten Ladeinfrastruktur-Lösungen von namhaften, europäischen Ladesystem-Herstellern.",
    '#AB5-eSystem-Klick-Explainer#': "Das optimale Betriebskonzept für einen elektrisch angetriebenen Bus hängt stark von den jeweiligen Einsatzbedingungen ab. Die eMobilitäts-Berater von Daimler Buses Solutions leiten aus den örtlichen Gegebenheiten ab, welche Ladeinfrastruktur am besten geeignet ist: Steckerladung auf dem Betriebshof oder eine Kombination mit Pantografen auf der Strecke. Darüber hinaus erhalten Sie auf Basis des Einsatzprofils Empfehlungen für die passende Ladetechnik.",
    '#BP1-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Ladeinfrastruktur und Fahrzeug aus einer Hand",
    '#BP2-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Perfektes Zusammenspiel von Lademanagement, Infrastruktur und Fahrzeug",
    '#BP3-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Garantierte Kompatibilität mit den eCitaro Batteriesystemen – dank ausgiebiger Interoperabilitätstests",
    '#ZHL6-eSystem-Klick-Explainer#': "Ersatzteile, eServiceVerträge und ServiceNetz",
    '#SL6-eSystem-Klick-Explainer#': "Mit den OMNIplus eServiceVerträgen für Elektrobusse bieten wir Ihnen fachgerechten Service zu planbaren Kosten.",
    '#AB6-eSystem-Klick-Explainer#': "Verlassen Sie sich auf unser bewährtes Angebot an OMNIplus eServiceVerträgen, die wir an die Anforderungen der Elektromobilität ausgerichtet haben. Sie können sich den optimalen Serviceumfang für Ihre Elektrobusflotte individuell und anforderungsgerecht zusammenstellen. Beispielsweise sind mit dem eServiceVertrag ePremium sämtliche Wartungen nach Herstellervorgaben und alle Reparaturen am Hochvoltsystem abgedeckt. Das Paket beinhaltet auch sonstige Reparaturen am gesamten Fahrzeug inklusive aller benötigten Ersatzteile. Für die professionelle Versorgung mit Originalteilen steht Ihnen das größte und dichteste Omnibus-ServiceNetz in Europa zur Verfügung – mit über 600 autorisierten Servicestützpunkten. So sind Sie immer sicher, zuverlässig und wirtschaftlich mit Ihrem vollelektrischen eCitaro unterwegs.",
    '#BP1-Ersatzteile-eSystem-Klick-Explainer#': "Zwei OMNIplus eServiceVerträge zur Auswahl: eBasic oder ePremium",
    '#BP2-Ersatzteile-eSystem-Klick-Explainer#': "Eine Variante für die Hochvoltbatterie: erweiterte Batteriegarantie",
    '#BP3-Ersatzteile-eSystem-Klick-Explainer#': "Zukunftssicheres Serviceangebot für Elektromobilität",
    '#BP4-Ersatzteile-eSystem-Klick-Explainer#': "Attraktive Paketpreise statt Einzelleistungen",
    '#BP5-Ersatzteile-eSystem-Klick-Explainer#': "Service- und Wartungsverträge auch für die Ladeinfrastruktur",
    '#ZHL7-eSystem-Klick-Explainer#': "Digitale Services",
    '#SL7-eSystem-Klick-Explainer#': "Wir bieten Ihnen die digitale Servicewelt von OMNIplus ON für Ihren Mercedes-Benz eCitaro und eCitaro fuel cell",
    '#AB7-eSystem-Klick-Explainer#': "OMNIplus ON bietet Ihnen eine Vielzahl an digitalen Serviceleistungen – für maximale Fahrzeugverfügbarkeit und größtmögliche Wirtschaftlichkeit. Wie zum Beispiel das OMNIplus ON Uptime pro mit Ferndiagnose. Damit haben Sie den Zustand Ihrer Fahrzeuge permanent im Blick und können so die Serviceplanung optimieren. Mit OMNIplus ON monitor haben Sie zudem die Möglichkeit den Ladezustand und die Restreichweite Ihrer Fahrzeuge zu überwachen sowie deren Energieverbrauch zu analysieren – zum Beispiel auch für den neuen Mercedes-Benz eCitaro fuel cell. Darüber hinaus ermöglichen die virtuellen Schnittstellen von OMNIplus ON eine einfache Datenintegration in Ihr eigenes Flottenmanagement- bzw. Betriebshofmanagement-System. So liefert das neue Data Package TiGR, das nach dem einheitlichen ITxPT-Standard zertifiziert ist, eine homogene Datenbasis für die gesamte Flotte und vereinfacht so Ihre Fahrzeug-Analysen. Übrigens: Sämtliche Fahrzeugdaten werden in Echtzeit und auf Wunsch auch im Rohformat bereitgestellt. Das geschieht jederzeit rechtssicher und im Rahmen der neuen UN-Regelung R155 hinsichtlich der Cybersicherheit von Fahrzeugen. Darüber hinaus kann mit den Data Packages eine volle Datenintegration in ihr eigenes Flottenmanagement- bzw. Betriebshofmanagementsystem u.a. mit dem neuen von ITxPT standardisierten TiGR Paket erreicht werden.",
    '#BP1-DigitaleServices-eSystem-Klick-Explainer#': "Größtmögliche Verfügbarkeit der Fahrzeuge",
    '#BP2-DigitaleServices-eSystem-Klick-Explainer#': "Transparenz über aktuelle Reichweite und Verbrauch Ihrer eCitaro Busse",
    '#BP3-DigitaleServices-eSystem-Klick-Explainer#': "Maximale Sicherheit Ihrer Daten",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff#': "Wasserstoffinfrastruktur",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy_head#': "Wir unterstützen Sie mit maßgeschneiderten\nInfrastrukturlösungen für eine funktionierende Wasserstoffversorgung.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy#': "Das optimale Betriebskonzept für einen alternativ angetriebenen Omnibus hängt stark von den jeweiligen Einsatzbedingungen ab. Zur Verlängerung der Reichweite hat unser Mercedes-Benz eCitaro fuel cell zusätzlich zu den Batterien eine mit Wasserstoff (H2) betriebene Brennstoffzelle an Bord. Die Daimler Buses eMobilitäts-Berater leiten aus den benötigten Mengen an Wasserstoff und den örtlichen Gegebenheiten ab, welche Tankinfrastruktur am besten geeignet ist. Wir analysieren, ob eine Wasserstofftankstelle in der Umgebung genutzt werden kann und vermitteln hierbei einen unserer\nKooperationspartner oder ob der Aufbau auf dem eigenen Betriebshof mittels einer eigenen Tankstelle sinnvoll ist. Wir setzen immer die beste Lösung für Ihre Flotte um.  Zusätzlich wird geprüft, ob die Wasserstoffversorgung mit einer Trailer-Lösung oder einem Elektrolyseur umgesetzt werden kann. Darüber hinaus erhalten Sie darauf abgestimmt Empfehlungen und Angebote für die passende Ladeinfrastruktur für Ihre Elektrobusflotte.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile#': "Ihre Vorteile:",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b1#': "Infrastruktur und Fahrzeug aus einer Hand",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b2#': "Perfektes Zusammenspiel von Lademanagement, Infrastruktur und Fahrzeug",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b3#': "Entscheidungshilfe bei der Wahl des optimalen H2-Betankungskonzepts",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b4#': "Zusammenarbeit mit erfahrenen Partnern",
} as const;
