// Don't change manually as this is generated automatically!

export const nl_NL = {
    '#Dictionary-eMobilitaets-Oekosystem#': "eMobility-ecosysteem",
    '#HL-eMobilitaetsOekosystem-DB#': "eMobility-ecosysteem van Daimler Buses",
    '#SHL-eSystem-Klick-Explainer#': "Systeem-design",
    '#SL-SystemDesign-eSystem-Klick-Explainer#': "Met softwareondersteunde haalbaarheidsstudies maken wij elektrisch openbaar vervoer betrouwbaar, planbaar en maken wij het systeemdesign voor uw depot.",
    '#AB1-SystemDesign-eSystem-Klick-Explainer#': "In een haalbaarheidsstudie controleren de eMobility-adviseur van Daimler Buses de energiebehoeften van alle bestaande lijnen en routes in een simulatiemodel dat speciaal is aangepast aan de eCitaro. Daarbij wordt de laadtoestand gedurende de dag bepaald en worden eventueel noodzakelijke laadpauzes voorgesteld. Geschikte buslijnen worden voor elektrische bussen geoptimaliseerd.",
    '#AB2-SystemDesign-eSystem-Klick-Explainer#': "Het systeemdesign omvat de inrichting van het depot als geheel, met inbegrip van de laadinfrastructuur. Dit omvat de berekening van het benodigde elektrische vermogen of de benodigde hoeveelheid energie. Bovendien worden laadconcepten gecreëerd en wordt het laadvermogen via het depotbeheer geoptimaliseerd.",
    '#ZHL-Vorteile-eSysem-Klick-Explainer#': "Uw voordelen",
    '#BP1-SystemDesign-eSystem-Klick-Explainer#': "Transparantie over de haalbaarheid van dagritten van bestaande busroutes met de eCitaro",
    '#BP2-SystemDesign-eSystem-Klick-Explainer#': "Ondersteuning voor route optimalisatie",
    '#BP3-SystemDesign-eSystem-Klick-Explainer#': "Hulp bij de besluitvorming voor de optimale batterij configuratie en het optimale laadconcept",
    '#BP4-SystemDesign-eSystem-Klick-Explainer#': "Algemeen concept voor de omschakeling van het depot op de exploitatie van elektrische bussen",
    '#ZHL1-eSystem-Klick-Explainer#': "Batterij opslag",
    '#SL1-eSystem-Klick-Explainer#': "Samen met Mercedes-Benz Energy geven wij gebruikte bus batterijen een tweede leven.",
    '#AB1-eSystem-Klick-Explainer#': "Met een stationaire energie-batterijmulator op basis van gebruikte batterijen voor elektrische bussen kan hun levensduur aanzienlijk worden verlengd en de duurzaamheid van uw elektrische busvloot verder worden verhoogd. In combinatie met een intelligent laad- en energiemanagementsysteem kunt u bovendien vermogenspieken verminderen en zo de stroomkosten verlagen. Verdere optimalisatiemogelijkheden ontstaan in combinatie met een PV-installatie die overdag de regeneratieve energie opwekt, die dan 's nachts kan worden gebruikt om de bussen op te laden.",
    '#BP1-eSystem-Klick-Explainer#': "Verhoging van de economische efficiëntie",
    '#BP2-eSystem-Klick-Explainer#': "Vermindering van piekbelasting",
    '#BP3-eSystem-Klick-Explainer#': "Verbetering van de CO2-footprint",
    '#ZHL2-eSystem-Klick-Explainer#': "Transformator",
    '#SL2-eSystem-Klick-Explainer#': "Wij adviseren u bij de individuele keuze van de juiste transformator voor uw depot.",
    '#BE1_eSystem-Klick-Explainer#': "Klantendepot",
    '#AB2-eSystem-Klick-Explainer#': "Om een vloot batterij gedreven bussen van energie te voorzien, moet de energie van het middenspanningsnet (gewoonlijk 6 kV - 60 kV) worden teruggebracht tot een spanningsniveau van 400V AC. Afhankelijk van de eisen en de beschikbare ruimte in het depot kan dit met een compacte transformator of bij grote wagenparken met een begaanbaar transformatorstation gebeuren.",
    '#BP1-Transformator-eSystem-Klick-Explainer#': "Levering van het vereiste totale vermogen afhankelijk van de omvang van de busvloot en het laadconcept",
    '#BP2-Transformator-eSystem-Klick-Explainer#': "Planningszekerheid door afstemming van de energievoorziening op geplande uitbreidingsfasen van uw busvloot",
    '#ZHL3-eSystem-Klick-Explainer#': "Bouwwerkzaamheden en elektrische installaties",
    '#SL3-eSystem-Klick-Explainer#': "Wij zorgen voor een efficiënte en veilige energievoorziening op uw bedrijfsterrein – van planning tot uitvoering.",
    '#AB3-eSystem-Klick-Explainer#': "De eMobility-adviseur nemen de planning van alle noodzakelijke bouwwerkzaamheden voor de stroomvoorziening voor hun rekening. Coördinatie en uitvoering ligt in de hand van een centrale projectleiding.",
    '#BP1-Bauarbeiten-eSystem-Klick-Explainer#': "Uitvoering met het oog op de algemene uitbreidingsdoelstelling van het depot",
    '#BP2-Bauarbeiten-eSystem-Klick-Explainer#': "Aandacht voor individuele operationele processen in de bouwplanning",
    '#BP3-Bauarbeiten-eSystem-Klick-Explainer#': "Bouwwerkzaamheden kunnen tijdens bedrijf worden uitgevoerd",
    '#ZHL4-eSystem-Klick-Explainer#': "Laad- en depotbeheer",
    '#SL4-eSystem-Klick-Explainer#': "Met onze oplossingen voor laad- en depotbeheer hebt u altijd een overzicht van alle laadprocessen en kunt u uw stroomkosten aanzienlijk verlagen.",
    '#AB4-eSystem-Klick-Explainer#': "Met een professioneel laadbeheer kunt u het laadvermogen van het gehele wagenpark optimaliseren, zodat uw bussen bij gecontroleerd totaal vermogen tijdig zijn opgeladen en voorgeconditioneerd. Dure netbelastingspieken worden zo gereduceerd en de afname van stroom kan in het gunstigste prijsvenster plaatsvinden. De laadplannen worden automatisch binnen de opgegeven belastingvensters opgesteld en ook de preconditionering van de voertuigen wordt centraal geregeld om de maximale actieradius van de bussen te garanderen. Daarvoor bieden wij overzichtelijke laadbeheeroplossingen voor kleine wagenparken, maar kunnen ook grote elektrische busvloten met geïntegreerde oplossingen voor het elektrsiche depotbeheer optimaal sturen en omlooptijden en parkeerplaatsen doelgericht toewijzen.",
    '#BP1-Lademanagement-eSystem-Klick-Explainer#': "Kostenverlaging door geringe belastingspieken",
    '#BP2-Lademanagement-eSystem-Klick-Explainer#': "Automatische laadplannen voor alle bussen, afgestemd op vertrektijden en omlooptijden",
    '#BP3-Lademanagement-eSystem-Klick-Explainer#': "Centrale regeling van de voorconditionering voor maximale actieradius en verhoogd comfort",
    '#BP4-Lademanagement-eSystem-Klick-Explainer#': "Netvriendelijk laden in het beste prijsvenster",
    '#ZHL5-eSystem-Klick-Explainer#': "Laadinfrastructuur",
    '#SL5-eSystem-Klick-Explainer#': "Wij ondersteunen u met op maat gemaakte stroomvoorzieningsoplossingen van gerenommeerde Europese fabrikanten van laadsystemen.",
    '#AB5-eSystem-Klick-Explainer#': "Het optimale bedrijfsconcept voor een elektrisch aangedreven bus hangt sterk af van de respectieve bedrijfsomstandigheden. De eMobility-adviseur van Daimler Buses Solutions leiden uit de plaatselijke omstandigheden af welke laadinfrastructuur het best geschikt is: stekkerlading in het depot of een combinatie met pantografen op het traject. Bovendien krijgt u op basis van het toepassingsprofiel aanbevelingen voor de passende laadtechniek.",
    '#BP1-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Laadinfra en voertuig uit één hand",
    '#BP2-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Perfect samenspel van laadmanagement, infrastructuur en voertuig",
    '#BP3-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Gegarandeerde compatibiliteit met de eCitaro batterijsystemen – dankzij uitgebreide interoperabiliteitstests",
    '#ZHL6-eSystem-Klick-Explainer#': "Onderdelen, servicecontracten en servicenetwerk",
    '#SL6-eSystem-Klick-Explainer#': "Met de OMNIplus eService-contracten voor elektrische bussen bieden wij u professionele service tegen planbare kosten.",
    '#AB6-eSystem-Klick-Explainer#': "Vertrouw op ons beproefde aanbod van OMNIplus eService-contracten, die wij op de eisen van elektromobiliteit hebben afgestemd. U kunt de optimale onderhoudsbeurt voor uw elektrische busvloot individueel en naar behoefte samenstellen. Zo zijn met het eService-contract ePremium alle onderhoudswerkzaamheden volgens de voorschriften van de fabrikant en alle reparaties aan het hoogspanningsboordnet gedekt. Het pakket omvat ook andere reparaties aan het gehele voertuig, inclusief alle benodigde onderdelen. Voor de professionele levering van originele onderdelen staat het grootste en dichtste busservicenetwerk in Europa tot uw beschikking – met meer dan 600 erkende servicepunten. Zo bent u altijd veilig, betrouwbaar en voordelig onderweg met uw volledig elektrische eCitaro.",
    '#BP1-Ersatzteile-eSystem-Klick-Explainer#': "Twee OMNIplus eServicecontracten naar keuze: eBasic of ePremium",
    '#BP2-Ersatzteile-eSystem-Klick-Explainer#': "Een variant voor de hoogspanningsbatterij: uitgebreide batterij garantie",
    '#BP3-Ersatzteile-eSystem-Klick-Explainer#': "Toekomstbestendig serviceaanbod voor elektromobiliteit",
    '#BP4-Ersatzteile-eSystem-Klick-Explainer#': "Aantrekkelijke pakketprijzen in plaats van afzonderlijke diensten",
    '#BP5-Ersatzteile-eSystem-Klick-Explainer#': "Service- en onderhoudscontracten ook voor de laadinfrastructuur.",
    '#ZHL7-eSystem-Klick-Explainer#': "Digitale services",
    '#SL7-eSystem-Klick-Explainer#': "Wij bieden u de digitale servicewereld van OMNIplus ON voor uw Mercedes-Benz eCitaro en eCitaro fuel cell.",
    '#AB7-eSystem-Klick-Explainer#': "OMNIplus ON biedt u een groot aantal digitale services – voor een maximale voertuigbeschikbaarheid en maximale efficiëntie. Zoals bijvoorbeeld de OMNIplus ON Uptime pro met diagnose op afstand. Daarmee houdt u de toestand van uw voertuigen permanent in het oog en kunt u de serviceplanning optimaliseren. Met OMNIplus ON monitor kunt u bovendien de laadtoestand en de resterende actieradius van uw voertuigen bewaken en hun energieverbruik analyseren – bijvoorbeeld ook voor de nieuwe Mercedes-Benz eCitaro fuel cell. Daarnaast maken de virtuele interfaces van OMNIplus ON een eenvoudige gegevensintegratie in uw eigen wagenparkbeheer- of depotbeheersysteem mogelijk. Zo levert het nieuwe Data Package TiGR, dat volgens de uniforme ITxPT-standaard gecertificeerd is, een homogene gegevensbasis voor het gehele wagenpark en vereenvoudigt zo uw voertuiganalyses. Alle voertuiggegevens worden in real-time en desgewenst ook in het onbewerkt ter beschikking gesteld. Dit gebeurt te allen tijde legally compliant en in het kader van de nieuwe UN-regeling R155 met betrekking tot de cyber sercurity van voertuigen. Bovendien kan met de Data Packages een volledige gegevensintegratie in uw eigen wagenpark- of depotbeheersysteem o.a. met het nieuwe, door het ITxPT gestandaardiseerde TiGR-pakket worden bereikt.",
    '#BP1-DigitaleServices-eSystem-Klick-Explainer#': "Maximale voertuig beschikbaarheid",
    '#BP2-DigitaleServices-eSystem-Klick-Explainer#': "Transparantie over de actuele actieradius en het verbruik van de eCitaro voertuigen",
    '#BP3-DigitaleServices-eSystem-Klick-Explainer#': "Maximale beveiliging van uw gegevens",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff#': "Waterstofinfrastructuur",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy_head#': "Wij ondersteunen u met op maat gemaakte\ninfrastructuuroplossingen voor een functionerende waterstofvoorziening.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy#': "Het optimale bedrijfsconcept voor een alternatief aangedreven bus is sterk afhankelijk van de betreffende gebruiksomstandigheden. Om de actieradius te vergroten, hebben onze Mercedes-Benz eCitaro fuel cell bussen naast de batterijen een met waterstof (H2) aangedreven brandstofcel aan boord. De eMobility-adviseurs van Daimler Buses leiden uit de benodigde hoeveelheden waterstof en de plaatselijke omstandigheden af welke tankinfrastructuur het best geschikt is. We analyseren of een waterstoftankstation in de omgeving kan worden gebruikt en informeren hierbij één van onze\nsamenwerkingspartners of dat de bouw van een eigen tankstation op het eigen bedrijfsterrein zinvol is. Wij implementeren altijd de beste oplossing voor uw wagenpark.  Bovendien wordt gecontroleerd of de waterstofvoorziening met een traileroplossing of een elektrolyser kan worden gerealiseerd. Daarnaast krijgt u daarop afgestemde aanbevelingen en aanbiedingen voor de passende stroomvoorziening voor uw elektrische busvloot.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile#': "Uw voordelen:",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b1#': "Infrastructuur en voertuig van één fabrikant",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b2#': "Perfect samenspel van laadbeheer, infrastructuur en voertuig",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b3#': "Hulp bij het kiezen van het optimale H2-tankconcept",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b4#': "Samenwerking met ervaren partners",
} as const;
