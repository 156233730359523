import styled, {keyframes} from 'styled-components';
import {DimensionProps} from '../customTypes';

const slideInAnimation = () => keyframes`
  0% {
    transform: translate(-300%, -50%);
  }

  100% {
    transform: translate(0, -50%);
  }
`;

const fadeInAnimation = () => keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const zoomAnimation = () => keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
  
  100% {
    transform: scale(1);
  }
`;

export const QuoteImage = styled.div<DimensionProps>`
    width: ${DimensionProps => DimensionProps.width}vW;
    position: absolute;
    top: 50%;
    left: 9%;
    transform: translate(-300%, -50%);
    animation: ${slideInAnimation} .4s cubic-bezier(.47,1.64,.41,.8) forwards;
    
    svg {
        .main-image {
            image {
                animation: ${zoomAnimation} 10s ease-in-out 1s infinite;
            }
        }
    }
`;

export const QuoteContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(0, -50%);
  max-width: 30%;
  opacity: 0;
  animation ${fadeInAnimation} .5s ease-in-out forwards;
`;

export const QuoteTitle = styled.div`
  width: 16rem;
  height: 6rem;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  align-items: center;
  padding-left: 1.6rem;
  box-sizing: border-box;
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 2rem;
`;