import {
    ContentInfoBoxListContainer,
    ContentInfoBoxListItem
} from '../../assets/ts/components/contentinfobox';

interface ContentInfoBoxListProps {
    list: string[];
}

export const ContentInfoBoxList = (props: ContentInfoBoxListProps) => {
    const {list} = props;

    return (
        <ContentInfoBoxListContainer>
            {list.map((text: string, key: number) => (<ContentInfoBoxListItem key={key}>{text}</ContentInfoBoxListItem>))}
        </ContentInfoBoxListContainer>
    );
}
