import { Locale } from '../ts/customTypes';
import { Pages } from '../ts/customTypes';
import {BREAKPOINT} from '../../config';
import { it_IT as cmLocale } from '../locale-content-hub/it_IT';

// Background
import LandingpageImage from '../imgs/bg-img-full-it.jpg';

// Icons
import IconBenefits from '../../assets/icons/content/benefits.svg';

// Content Images
import MainBattery from '../../assets/content/main-battery.jpg';
import MainCharging from '../../assets/content/main-charginginfra.jpg';
import MainConstruction from '../../assets/content/main-construction.jpg';
import MainDigitalService from '../../assets/content/main-digitalservice.jpg';
import MainSystemDesign from '../content/main-systemdesign.png';
import MainSpareParts from '../../assets/content/main-spareparts.jpg';
import MainManagement from '../content/main-management.jpg';
import MainTransformer from '../../assets/content/main-transformer.jpg';
import MainHydrogen from '../content/main-hydrogen.jpg';
import ZoomBattery from '../../assets/content/zoom-battery.png';
import ZoomCharging from '../../assets/content/zoom-charginginfra.png';
import ZoomConstruction from '../../assets/content/zoom-construction.png';
import ZoomDigitalService from '../content/zoom-digital-service.png';
import ZoomSystemDesign from '../content/zoom-systemdesign.png';
import ZoomSpareParts from '../../assets/content/zoom-spareparts.png';
import ZoomManagement from '../content/zoom-management.png';
import ZoomTransformer from '../../assets/content/zoom-transformer.png';
import ZoomHydrogen from '../content/zoom-hydrogen.png';

export const it: Locale = {
    language: 'IT',
    headerHeading: cmLocale['#Dictionary-eMobilitaets-Oekosystem#'],
    overview: '', // TODO: Remove?
    infolayer: {
        text: (
            <>
                <p>La visualizzazione di questo contenuto richiede una larghezza minima di {BREAKPOINT} pixel.</p>
                <p>Passa a un dispositivo adatto per visualizzarlo.</p>
            </>
        ),
    },
    [Pages.LANDINGPAGE]: {
        button: cmLocale['#SHL-eSystem-Klick-Explainer#'],
        feedback: '', // TODO: Remove?
        background: LandingpageImage,
    },
    [Pages.FEEDBACKPAGE]: {
        headline: '',
        image: '',
        quote: {
            title: '',
            text: '',
            author: '',
        },
    },
    pages: {
        [Pages.SYSTEMDESIGN]: {
            headline: cmLocale['#SHL-eSystem-Klick-Explainer#'],
            intro: cmLocale['#SL-SystemDesign-eSystem-Klick-Explainer#'],
            text:
                <>
                    <p>{cmLocale['#AB1-SystemDesign-eSystem-Klick-Explainer#']}</p>
                    <p>{cmLocale['#AB2-SystemDesign-eSystem-Klick-Explainer#']}</p>
                </>,
            infoBoxes: [
                {
                    icon: IconBenefits,
                    headline: cmLocale['#ZHL-Vorteile-eSysem-Klick-Explainer#'],
                    list: [
                        cmLocale['#BP1-SystemDesign-eSystem-Klick-Explainer#'],
                        cmLocale['#BP2-SystemDesign-eSystem-Klick-Explainer#'],
                        cmLocale['#BP3-SystemDesign-eSystem-Klick-Explainer#'],
                        cmLocale['#BP4-SystemDesign-eSystem-Klick-Explainer#'],
                    ],
                },
            ],
            images: {
                main: MainSystemDesign,
                zoom: ZoomSystemDesign,
            },
        },
        [Pages.MANAGEMENT]: {
            headline: cmLocale['#ZHL4-eSystem-Klick-Explainer#'],
            intro: cmLocale['#SL4-eSystem-Klick-Explainer#'],
            text: <p>{cmLocale['#AB4-eSystem-Klick-Explainer#']}</p>,
            infoBoxes: [
                {
                    icon: IconBenefits,
                    headline: cmLocale['#ZHL-Vorteile-eSysem-Klick-Explainer#'],
                    list: [
                        cmLocale['#BP1-Lademanagement-eSystem-Klick-Explainer#'],
                        cmLocale['#BP2-Lademanagement-eSystem-Klick-Explainer#'],
                        cmLocale['#BP3-Lademanagement-eSystem-Klick-Explainer#'],
                        cmLocale['#BP4-Lademanagement-eSystem-Klick-Explainer#'],
                    ],
                },
            ],
            images: {
                main: MainManagement,
                zoom: ZoomManagement,
            },
        },
        [Pages.CHARGING]: {
            headline: cmLocale['#ZHL5-eSystem-Klick-Explainer#'],
            intro: cmLocale['#SL5-eSystem-Klick-Explainer#'],
            text: <p>{cmLocale['#AB5-eSystem-Klick-Explainer#']}</p>,
            infoBoxes: [
                {
                    icon: IconBenefits,
                    headline: cmLocale['#ZHL-Vorteile-eSysem-Klick-Explainer#'],
                    list: [
                        cmLocale['#BP1-Ladeinfrastruktur-eSystem-Klick-Explainer#'],
                        cmLocale['#BP2-Ladeinfrastruktur-eSystem-Klick-Explainer#'],
                        cmLocale['#BP3-Ladeinfrastruktur-eSystem-Klick-Explainer#'],
                    ],
                },
            ],
            images: {
                main: MainCharging,
                zoom: ZoomCharging,
            },
        },
        [Pages.DIGITALSERVICE]: {
            headline: cmLocale['#ZHL7-eSystem-Klick-Explainer#'],
            intro: cmLocale['#SL7-eSystem-Klick-Explainer#'],
            text: <p>{cmLocale['#AB7-eSystem-Klick-Explainer#']}</p>,
            infoBoxes: [
                {
                    icon: IconBenefits,
                    headline: cmLocale['#ZHL-Vorteile-eSysem-Klick-Explainer#'],
                    list: [
                        cmLocale['#BP1-DigitaleServices-eSystem-Klick-Explainer#'],
                        cmLocale['#BP2-DigitaleServices-eSystem-Klick-Explainer#'],
                        cmLocale['#BP3-DigitaleServices-eSystem-Klick-Explainer#'],
                    ],
                },
            ],
            images: {
                main: MainDigitalService,
                zoom: ZoomDigitalService,
            },
        },
        [Pages.TRANSFORMER]: {
            headline: cmLocale['#ZHL2-eSystem-Klick-Explainer#'],
            intro: cmLocale['#SL2-eSystem-Klick-Explainer#'],
            text: <p>{cmLocale['#AB2-eSystem-Klick-Explainer#']}</p>,
            infoBoxes: [
                {
                    icon: IconBenefits,
                    headline: cmLocale['#ZHL-Vorteile-eSysem-Klick-Explainer#'],
                    list: [
                        cmLocale['#BP1-Transformator-eSystem-Klick-Explainer#'],
                        cmLocale['#BP2-Transformator-eSystem-Klick-Explainer#'],
                    ],
                },
            ],
            images: {
                main: MainTransformer,
                zoom: ZoomTransformer,
            },
        },
        [Pages.BATTERY]: {
            headline: cmLocale['#ZHL1-eSystem-Klick-Explainer#'],
            intro: cmLocale['#SL1-eSystem-Klick-Explainer#'],
            text: <p>{cmLocale['#AB1-eSystem-Klick-Explainer#']}</p>,
            infoBoxes: [
                {
                    icon: IconBenefits,
                    headline: cmLocale['#ZHL-Vorteile-eSysem-Klick-Explainer#'],
                    list: [
                        cmLocale['#BP1-eSystem-Klick-Explainer#'],
                        cmLocale['#BP2-eSystem-Klick-Explainer#'],
                        cmLocale['#BP3-eSystem-Klick-Explainer#'],
                    ],
                },
            ],
            images: {
                main: MainBattery,
                zoom: ZoomBattery,
            },
        },
        [Pages.CONSTRUCTION]: {
            headline: cmLocale['#ZHL3-eSystem-Klick-Explainer#'],
            intro: cmLocale['#SL3-eSystem-Klick-Explainer#'],
            text: <p>{cmLocale['#AB3-eSystem-Klick-Explainer#']}</p>,
            infoBoxes: [
                {
                    icon: IconBenefits,
                    headline: cmLocale['#ZHL-Vorteile-eSysem-Klick-Explainer#'],
                    list: [
                        cmLocale['#BP1-Bauarbeiten-eSystem-Klick-Explainer#'],
                        cmLocale['#BP2-Bauarbeiten-eSystem-Klick-Explainer#'],
                        cmLocale['#BP3-Bauarbeiten-eSystem-Klick-Explainer#'],
                    ],
                },
            ],
            images: {
                main: MainConstruction,
                zoom: ZoomConstruction,
            },
        },
        [Pages.SPAREPARTS]: {
            headline: cmLocale['#ZHL6-eSystem-Klick-Explainer#'],
            intro: cmLocale['#SL6-eSystem-Klick-Explainer#'],
            text: <p>{cmLocale['#AB6-eSystem-Klick-Explainer#']}</p>,
            infoBoxes: [
                {
                    icon: IconBenefits,
                    headline: cmLocale['#ZHL-Vorteile-eSysem-Klick-Explainer#'],
                    list: [
                        cmLocale['#BP1-Ersatzteile-eSystem-Klick-Explainer#'],
                        cmLocale['#BP2-Ersatzteile-eSystem-Klick-Explainer#'],
                        cmLocale['#BP3-Ersatzteile-eSystem-Klick-Explainer#'],
                        cmLocale['#BP4-Ersatzteile-eSystem-Klick-Explainer#'],
                        cmLocale['#BP5-Ersatzteile-eSystem-Klick-Explainer#'],
                    ],
                },
            ],
            images: {
                main: MainSpareParts,
                zoom: ZoomSpareParts,
            },
        },
        [Pages.HYDROGEN]: {
            headline: cmLocale['#ZHL7-eSystem-Klick-Explainer_Wasserstoff#'],
            intro: cmLocale['#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy_head#'],
            text: <p>{cmLocale['#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy#']}</p>,
            infoBoxes: [
                {
                    icon: IconBenefits,
                    headline: cmLocale['#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile#'],
                    list: [
                        cmLocale['#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b1#'],
                        cmLocale['#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b2#'],
                        cmLocale['#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b3#'],
                        cmLocale['#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b4#'],
                    ],
                },
            ],
            images: {
                main: MainHydrogen,
                zoom: ZoomHydrogen,
            },
        },
    },
};
