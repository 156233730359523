import {InfoLayerContainer, InfoLayerContent, InfoLayerText} from '../../assets/ts/components/infolayer';
import {Locale} from '../../assets/ts/customTypes';

interface InfoLayerProps {
    locale: Locale,
}

export function InfoLayer(props: InfoLayerProps) {
    const {locale} = props;
    const {text} = locale.infolayer;

    return(
        <InfoLayerContainer>
            <InfoLayerContent>
                <InfoLayerText>{text}</InfoLayerText>
            </InfoLayerContent>
        </InfoLayerContainer>
    );
}