import {Languages} from '../../assets/ts/customTypes';
import {HeaderContainer} from '../../assets/ts/layout/header';
import {LanguagePicker} from './LanguagePicker';

interface HeaderProps {
    language: Languages,
    heading: string,
    updateLanguage: (language: Languages) => void
}

const HeaderImage = (props: HeaderProps) => {
    const {heading} = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 105">
            <defs>
                <linearGradient id="linear-gradient" x1=".36" y1="495.28" x2="1" y2="495.28" gradientTransform="translate(0 2331.5) scale(1920 -4.5)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#222"/>
                    <stop offset="1" stopColor="#ededed"/>
                </linearGradient>
            </defs>
            <g id="header">
                <polygon id="background" points="0 104.5 1920 104.5 1920 0 0 0 0 104.5" fill="#222" />
                <polygon id="background-bar" points="0 105 1920 105 1920 100.5 0 100.5 0 105" fill ="url(#linear-gradient)" />
                <text transform="translate(77.05 66.28)" fontSize="40">{heading}</text>
            </g>
        </svg>
    )
}

export const Header = (props: HeaderProps) => {
    const {language, updateLanguage} = props;

    return (
        <HeaderContainer>
            <HeaderImage {...props} />
            <LanguagePicker language={language} updateLanguage={updateLanguage} />
        </HeaderContainer>
    );
};