import {getRelativeWidth} from '../../config';
import {PageHeadlineContainer, PageHeadlineText} from '../../assets/ts/components/pageheadline';
import ButtonContainerBG from '../../assets/backgrounds/bg-overview-button.svg';

export function PageHeadline({text}: { text: string; }) {
    return (
        <PageHeadlineContainer width={getRelativeWidth(404)}>
            <img src={ButtonContainerBG} alt='background'/>
            <PageHeadlineText>{text}</PageHeadlineText>
        </PageHeadlineContainer>
    );
}