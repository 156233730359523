import {getRelativeWidth} from '../../config';
import {
    ContentInfoBoxContainer,
    ContentInfoBoxIcon,
    ContentInfoBoxHeadline,
    ContentInfoBoxListWrapper
} from '../../assets/ts/components/contentinfobox';
import {ContentInfoBoxList} from './ContentInfoBoxList';

interface ContentInfoBoxProps {
    icon: string;
    headline: string;
    list: string[];
}

export const ContentInfoBox = (props: ContentInfoBoxProps) => {
    const {icon, headline, list} = props;

    return (
        <ContentInfoBoxContainer>
            <ContentInfoBoxIcon width={getRelativeWidth(90)}>
                <img src={icon} alt='' />
            </ContentInfoBoxIcon>
            <ContentInfoBoxListWrapper>
                <ContentInfoBoxHeadline>{headline}</ContentInfoBoxHeadline>
                <ContentInfoBoxList list={list} />
            </ContentInfoBoxListWrapper>
        </ContentInfoBoxContainer>
    );
}