// Don't change manually as this is generated automatically!

export const it_IT = {
    '#Dictionary-eMobilitaets-Oekosystem#': "Ecosistema dell'elettromobilità",
    '#HL-eMobilitaetsOekosystem-DB#': "L'ecosistema di elettromobilità di Daimler Buses",
    '#SHL-eSystem-Klick-Explainer#': "Design del sistema",
    '#SL-SystemDesign-eSystem-Klick-Explainer#': "Grazie a studi di fattibilità supportati da software, rendiamo il trasporto pubblico urbano elettrico pianificabile in modo affidabile e realizziamo la progettazione del sistema per il vostro deposito.",
    '#AB1-SystemDesign-eSystem-Klick-Explainer#': "Nel corso di uno studio di fattibilità, i consulenti di elettromobilità di Daimler Buses analizzano il fabbisogno energetico delle linee e dei percorsi esistenti utilizzando un modello di simulazione specificamente progettato per l’eCitaro. Monitorano il livello di carica durante la giornata e raccomandano eventuali pause di ricarica necessarie. Le linee saranno ottimizzate per garantire un'efficienza ottimale degli autobus elettrici.",
    '#AB2-SystemDesign-eSystem-Klick-Explainer#': "Il design del sistema comprende sia il layout complessivo del deposito che l'infrastruttura di ricarica. Questo include il calcolo della potenza elettrica necessaria e la quantità di energia richiesta. Inoltre, vengono sviluppati i concetti di ricarica e ottimizzata la potenza di ricarica attraverso una gestione efficiente del deposito.",
    '#ZHL-Vorteile-eSysem-Klick-Explainer#': "I vostri vantaggi",
    '#BP1-SystemDesign-eSystem-Klick-Explainer#': "Trasparenza sulla fattibilità delle corse giornaliere delle linee di autobus esistenti con l'eCitaro",
    '#BP2-SystemDesign-eSystem-Klick-Explainer#': "Supporto per l'ottimizzazione del ricircolo",
    '#BP3-SystemDesign-eSystem-Klick-Explainer#': "Guida alla scelta della configurazione ottimale della batteria e del concetto di ricarica",
    '#BP4-SystemDesign-eSystem-Klick-Explainer#': "Piano complessivo per il passaggio del deposito\nalla gestione di autobus elettrici",
    '#ZHL1-eSystem-Klick-Explainer#': "Accumulatore a batteria",
    '#SL1-eSystem-Klick-Explainer#': "Insieme a Mercedes-Benz Energy, diamo una seconda vita alle batterie usate degli autobus.",
    '#AB1-eSystem-Klick-Explainer#': "Utilizzando accumulatori di energia stazionari realizzati con batterie di autobus elettrici usate, è possibile estendere significativamente il ciclo di vita delle batterie e migliorare la sostenibilità della flotta. Inoltre, l'adozione di un sistema intelligente di ricarica e gestione dell'energia consente di ridurre i picchi di potenza e, di conseguenza, i costi energetici. Ulteriori vantaggi possono essere ottenuti integrando un impianto fotovoltaico, che produce energia rigenerativa durante il giorno e può essere utilizzata per la ricarica notturna degli autobus.",
    '#BP1-eSystem-Klick-Explainer#': "Aumento della redditività",
    '#BP2-eSystem-Klick-Explainer#': "Numero ridotto di picchi di carico",
    '#BP3-eSystem-Klick-Explainer#': "Riduzione dell'impatto ambientale",
    '#ZHL2-eSystem-Klick-Explainer#': "Trasformatore",
    '#SL2-eSystem-Klick-Explainer#': "Vi offriamo consulenza per la scelta del trasformatore più adatto al vostro deposito.",
    '#BE1_eSystem-Klick-Explainer#': "Deposito dei clienti",
    '#AB2-eSystem-Klick-Explainer#': "Per poter erogare energia a una flotta di autobus alimentati a batteria, l'energia proveniente dalla rete di media tensione (generalmente 6 kV - 60 kV) deve essere ridotta a un livello di tensione di 400 V CA. A seconda delle esigenze e delle condizioni di spazio nel deposito del veicolo, ciò può avvenire con un trasformatore compatto o, nel caso di flotte di grandi dimensioni, con una stazione di trasformazione accessibile.",
    '#BP1-Transformator-eSystem-Klick-Explainer#': "Fornitura della potenza complessiva richiesta, basata sulla dimensione della flotta di autobus e sul piano di ricarica.",
    '#BP2-Transformator-eSystem-Klick-Explainer#': "Pianificazione sicura mediante il coordinamento della fornitura di energia con le fasi di ampliamento previste per la vostra flotta di autobus.",
    '#ZHL3-eSystem-Klick-Explainer#': "Lavori di costruzione e impianti elettrici",
    '#SL3-eSystem-Klick-Explainer#': "Garantiamo un'energia efficiente e sicura per il vostro deposito, dalla pianificazione all’implementazione.",
    '#AB3-eSystem-Klick-Explainer#': "I consulenti di elettromobilità di Daimler Buses gestiranno la progettazione di tutte le opere di costruzione necessarie per l'alimentazione dei caricabatterie. La coordinazione e l'esecuzione dei lavori saranno affidate a una direzione centrale del progetto.",
    '#BP1-Bauarbeiten-eSystem-Klick-Explainer#': "L'implementazione sarà realizzata tenendo in considerazione l'obiettivo complessivo di ampliamento del deposito aziendale.",
    '#BP2-Bauarbeiten-eSystem-Klick-Explainer#': "Considerazione dei singoli processi aziendali nella pianificazione della costruzione",
    '#BP3-Bauarbeiten-eSystem-Klick-Explainer#': "I lavori di costruzione possono essere eseguiti durante l'operatività",
    '#ZHL4-eSystem-Klick-Explainer#': "Gestione della ricarica e del deposito",
    '#SL4-eSystem-Klick-Explainer#': "Con le nostre soluzioni di gestione della ricarica e del deposito, è possibile monitorare costantemente tutte le operazioni di ricarica e ridurre significativamente i costi di elettricità.",
    '#AB4-eSystem-Klick-Explainer#': "Una gestione professionale della ricarica ottimizza l'uso della potenza per l'intera flotta, assicurando che gli autobus siano caricati e precondizionati in tempo, mantenendo il controllo sulla potenza totale. Questo approccio riduce i picchi di carico della rete e permette di acquistare elettricità al prezzo più vantaggioso. I piani di ricarica sono automaticamente pianificati all'interno delle finestre predefinite, e il precondizionamento dei veicoli è gestito centralmente per garantire la massima autonomia. Offriamo soluzioni di gestione della ricarica sia per piccole flotte che per grandi flotte di autobus elettrici, con sistemi integrati per la gestione del deposito, la pianificazione dei percorsi e l'assegnazione delle aree di parcheggio.",
    '#BP1-Lademanagement-eSystem-Klick-Explainer#': "Riduzione dei costi tramite minimizzazione dei picchi di carico",
    '#BP2-Lademanagement-eSystem-Klick-Explainer#': "Piani di ricarica automatici basati sugli orari di partenza e sui percorsi degli autobus",
    '#BP3-Lademanagement-eSystem-Klick-Explainer#': "Comando centralizzato del precondizionamento per la massima autonomia e un maggiore comfort",
    '#BP4-Lademanagement-eSystem-Klick-Explainer#': "Ricarica ottimizzata per la rete, sfruttando le fasce di prezzo più vantaggiose",
    '#ZHL5-eSystem-Klick-Explainer#': "Infrastruttura di ricarica",
    '#SL5-eSystem-Klick-Explainer#': "Vi supportiamo con soluzioni su misura per le infrastrutture di ricarica, realizzate da noti produttori europei di sistemi di ricarica.",
    '#AB5-eSystem-Klick-Explainer#': "Il funzionamento ottimale di un autobus elettrico dipende fortemente dalle condizioni specifiche di utilizzo. I consulenti di elettromobilità di Daimler Buses Solutions analizzeranno le vostre esigenze per determinare l'infrastruttura di ricarica più adatta: ricarica plug-in presso il deposito o una combinazione di ricarica plug-in e pantografi lungo il percorso. Inoltre, sulla base del profilo di utilizzo, riceverete raccomandazioni sulla tecnologia di ricarica più idonea.",
    '#BP1-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Infrastruttura di ricarica e veicolo da un unico fornitore",
    '#BP2-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Perfetta interazione tra gestione della ricarica, infrastruttura e veicolo",
    '#BP3-Ladeinfrastruktur-eSystem-Klick-Explainer#': "Compatibilità garantita con i sistemi di batterie dell'eCitaro, grazie a numerosi test di interoperabilità",
    '#ZHL6-eSystem-Klick-Explainer#': "Parti di ricambio, contratti eService e rete di assistenza",
    '#SL6-eSystem-Klick-Explainer#': "Con i contratti di assistenza OMNIplus eService per autobus elettrici, garantiamo un'assistenza professionale a costi prevedibili.",
    '#AB6-eSystem-Klick-Explainer#': "Affidatevi ai nostri contratti di assistenza OMNIplus eService, progettati su misura per le esigenze della mobilità elettrica. Potrete personalizzare la gamma di servizi ideali per la vostra flotta di autobus elettrici. Con il contratto eService ePremium, ad esempio, sono inclusi tutti gli interventi di manutenzione secondo le specifiche del produttore e le riparazioni della rete di bordo ad alta tensione. Il pacchetto copre anche le riparazioni dell’intero veicolo, comprensive di tutti i ricambi necessari. Grazie alla nostra vasta rete di assistenza, con oltre 600 punti autorizzati in tutta Europa, potete contare su un approvvigionamento professionale di ricambi originali. Così, il vostro eCitaro a trazione completamente elettrica sarà sempre sicuro, affidabile e redditizio.",
    '#BP1-Ersatzteile-eSystem-Klick-Explainer#': "Due contratti OMNIplus eService a scelta: eBasic o ePremium",
    '#BP2-Ersatzteile-eSystem-Klick-Explainer#': "Una variante per la batteria ad alto voltaggio: garanzia estesa sulla batteria",
    '#BP3-Ersatzteile-eSystem-Klick-Explainer#': "Offerta di servizi a prova di futuro per la mobilità elettrica",
    '#BP4-Ersatzteile-eSystem-Klick-Explainer#': "Pacchetti a prezzi vantaggiosi rispetto ai servizi singoli",
    '#BP5-Ersatzteile-eSystem-Klick-Explainer#': "Contratti di assistenza e manutenzione anche per l'infrastruttura di ricarica",
    '#ZHL7-eSystem-Klick-Explainer#': "Servizi digitali",
    '#SL7-eSystem-Klick-Explainer#': "Vi offriamo il mondo dei servizi digitali OMNIplus ON per il vostro Mercedes-Benz eCitaro ed eCitaro fuel cell.",
    '#AB7-eSystem-Klick-Explainer#': "OMNIplus ON offre una vasta gamma di servizi digitali per garantire la massima efficienza e disponibilità dei veicoli. Tra questi, OMNIplus ON Uptime pro con diagnosi remota consente di monitorare costantemente lo stato dei veicoli, ottimizzando così la pianificazione della manutenzione. Con OMNIplus ON Monitor, è possibile seguire il livello di carica, l'autonomia residua e il consumo di energia dei veicoli, incluso il nuovo eCitaro fuel cell di Mercedes-Benz.\n\nLe interfacce virtuali di OMNIplus ON facilitano l'integrazione dei dati nel proprio sistema di gestione della flotta o del deposito. Il nuovo Data Package TiGR, certificato secondo lo standard ITxPT, fornisce dati omogenei per tutta la flotta, semplificando l'analisi dei veicoli. I dati vengono forniti in tempo reale e, su richiesta, anche in formato grezzo, sempre in conformità con il regolamento UN R155 sulla sicurezza informatica dei veicoli. Inoltre, i Data Package permettono una completa integrazione dei dati nel sistema di gestione della flotta o del deposito, grazie al pacchetto TiGR standardizzato da ITxPT.",
    '#BP1-DigitaleServices-eSystem-Klick-Explainer#': "Massima disponibilità dei veicoli",
    '#BP2-DigitaleServices-eSystem-Klick-Explainer#': "Trasparenza sull'autonomia e sui consumi dei veicoli eCitaro",
    '#BP3-DigitaleServices-eSystem-Klick-Explainer#': "Massima sicurezza dei dati",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff#': "Infrastruttura per idrogeno",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy_head#': "Supportiamo i clienti con\nsoluzioni infrastrutturali su misura per garantire un approvvigionamento efficiente di idrogeno.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_copy#': "Il funzionamento ottimale di un autobus a trazione alternativa dipende significativamente dalle condizioni di utilizzo. Per estendere l'autonomia dei veicoli Mercedes-Benz eCitaro fuel cell, oltre alle batterie, questi autobus sono equipaggiati con una cella a combustibile alimentata a idrogeno (H2). Gli specialisti di elettromobilità di Daimler Buses valutano l'infrastruttura di rifornimento più adatta, considerando la quantità di idrogeno necessaria e le specifiche condizioni locali. Determinano se è più conveniente utilizzare un distributore di idrogeno esistente nelle vicinanze, in collaborazione con uno dei nostri partner, o se è preferibile installare una stazione di rifornimento dedicata nel proprio deposito. La nostra priorità è implementare la soluzione più efficace per la vostra flotta. Inoltre, i nostri esperti esaminano se è più adatta un'alimentazione tramite trailer o elettrolizzatore e offrono consulenze personalizzate e soluzioni per l'alimentazione elettrica che si adattano alle esigenze specifiche della vostra flotta di autobus elettrici.",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile#': "Vantaggi:",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b1#': "Infrastruttura e veicolo da un unico fornitore",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b2#': "Perfetta interazione tra gestione della ricarica, infrastruttura e veicolo",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b3#': "Supporto nella scelta del sistema di rifornimento di idrogeno più efficace",
    '#ZHL7-eSystem-Klick-Explainer_Wasserstoff_Vorteile_b4#': "Collaborazione con partner esperti",
} as const;
