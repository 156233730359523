import React, {ReactElement} from 'react';
import {Pages} from '../../assets/ts/customTypes';
/* navi icons */
import {ReactComponent as NaviBattery} from '../../assets/icons/navi/battery.svg';
import {ReactComponent as NaviChargeInfra} from '../../assets/icons/navi/charge-infra.svg';
import {ReactComponent as NaviConstruction} from '../../assets/icons/navi/construction.svg';
import {ReactComponent as NaviDigitalService} from '../../assets/icons/navi/digital-service.svg';
import {ReactComponent as NaviSpareParts} from '../../assets/icons/navi/spare-parts.svg';
import {ReactComponent as NaviManagement} from '../../assets/icons/navi/management.svg';
import {ReactComponent as NaviTransformer} from '../../assets/icons/navi/transformer.svg';
import {ReactComponent as NaviHydrogen} from '../../assets/icons/navi/hydrogen.svg';
/* landingpage icons */
import {ReactComponent as MapBattery} from '../../assets/icons/landingpage/battery.svg';
import {ReactComponent as MapChargeInfra} from '../../assets/icons/landingpage/charge-infra.svg';
import {ReactComponent as MapConstruction} from '../../assets/icons/landingpage/construction.svg';
import {ReactComponent as MapDigitalService} from '../../assets/icons/landingpage/digital-service.svg';
import {ReactComponent as MapSpareParts} from '../../assets/icons/landingpage/spare-parts.svg';
import {ReactComponent as MapManagement} from '../../assets/icons/landingpage/management.svg';
import {ReactComponent as MapTransformer} from '../../assets/icons/landingpage/transformer.svg';
import {ReactComponent as MapHydrogen} from '../../assets/icons/landingpage/hydrogen.svg';
/* content icons */
import {ReactComponent as ContentBattery} from '../../assets/icons/content/battery.svg';
import {ReactComponent as ContentChargeInfra} from '../../assets/icons/content/charge-infra.svg';
import {ReactComponent as ContentConstruction} from '../../assets/icons/content/construction.svg';
import {ReactComponent as ContentDigitalService} from '../../assets/icons/content/digital-service.svg';
import {ReactComponent as ContentSpareParts} from '../../assets/icons/content/spare-parts.svg';
import {ReactComponent as ContentManagement} from '../../assets/icons/content/management.svg';
import {ReactComponent as ContentTransformer} from '../../assets/icons/content/transformer.svg';
import {ReactComponent as ContentHydrogen} from '../../assets/icons/content/hydrogen.svg';

interface NavIconProps {
    page: Pages;
    type: 'map' | 'navi' | 'content';
}

type IconsType = {
    [Pages: string]: {
        map: ReactElement,
        navi: ReactElement,
        content: ReactElement,
    }
}

const icons: IconsType = {
    [Pages.BATTERY]: {
        map: <MapBattery/>,
        navi: <NaviBattery/>,
        content: <ContentBattery/>
    },
    [Pages.CHARGING]: {
        map: <MapChargeInfra/>,
        navi: <NaviChargeInfra/>,
        content: <ContentChargeInfra/>
    },
    [Pages.CONSTRUCTION]: {
        map: <MapConstruction/>,
        navi: <NaviConstruction/>,
        content: <ContentConstruction/>
    },
    [Pages.DIGITALSERVICE]: {
        map: <MapDigitalService/>,
        navi: <NaviDigitalService/>,
        content: <ContentDigitalService/>
    },
    [Pages.SPAREPARTS]: {
        map: <MapSpareParts/>,
        navi: <NaviSpareParts/>,
        content: <ContentSpareParts/>
    },
    [Pages.MANAGEMENT]: {
        map: <MapManagement/>,
        navi: <NaviManagement/>,
        content: <ContentManagement/>
    },
    [Pages.TRANSFORMER]: {
        map: <MapTransformer/>,
        navi: <NaviTransformer/>,
        content: <ContentTransformer/>
    },
    [Pages.HYDROGEN]: {
        map: <MapHydrogen/>,
        navi: <NaviHydrogen/>,
        content: <ContentHydrogen/>
    },
};

export const Icon = ({page, type}: NavIconProps) => {
    return icons[page][type];
}