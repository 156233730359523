import {ReactComponent as Connection} from '../../assets/imgs/system-design-lines.svg';
import {ConnectionWrapper} from '../../assets/ts/pages/landingpage';
import {getRelativeWidth} from '../../config';

export const ConnectionLines = () => {
    return (
        <ConnectionWrapper width={getRelativeWidth(1535.26)}>
            <Connection />
        </ConnectionWrapper>
    );
}