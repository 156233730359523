import styled from 'styled-components';
import WabeImage from '../../backgrounds/bg-wabe.svg';
import {BREAKPOINT} from '../../../config';

interface MainProps {
    name: 'landingpage' | 'infopage' | 'feedbackpage';
    background?: string;
}

export const MainContainer = styled.div<MainProps>`
  position: relative;
  flex: 1;
  display: none;
  
  @media (min-width: ${BREAKPOINT}px) {
    display: block;
  }
  
  ${MainProps => MainProps.name === 'landingpage' && `
        background: url(${MainProps.background}) no-repeat center / 100% auto;
    `}

  ${MainProps => MainProps.name === 'infopage' && `
        background: url(${WabeImage}) repeat, radial-gradient(at center, #e7e7e7 0, #ddd 100%);
    `}

  ${MainProps => MainProps.name === 'feedbackpage' && `
        background: url(${WabeImage}) repeat, radial-gradient(at center, #e7e7e7 0, #ddd 100%);
    `}
`;