import {getRelativeWidth} from '../../config';
import {Pages} from '../../assets/ts/customTypes';
import {
    LandingpageClickableContainer,
    LandingpageClickableIcon,
} from '../../assets/ts/pages/landingpage';
import {Icon} from './Icon';

export function LandingpageClickable({
    page,
    offsetLeft,
    offsetTop,
    width,
    position,
    updateCurrentPage
 }: { page: Pages; offsetLeft: number; offsetTop: number, width: number, position: number, updateCurrentPage: (page: Pages) => void }) {
    const delay = 1.4 + (position * 0.1);

    return (
        <LandingpageClickableContainer offsetLeft={offsetLeft} offsetTop={offsetTop} onClick={() => updateCurrentPage(page)}>
            <LandingpageClickableIcon width={getRelativeWidth(width)} delay={delay}>
                <Icon page={page} type={'map'}/>
            </LandingpageClickableIcon>
        </LandingpageClickableContainer>
    );
}